.dialog-box-image-upload {
    width: 400px;
}
.dialog-box-image-upload button {
    float: right;
    font-size: 14px;
    border: 1px solid #fff0;
    border-radius: 3px;
    padding: 0px 6px;
    background-color: #fff0;
}
.reuploadbtn {
    background-color: #3a3a3c!important;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
}
button.noopadd {
    /* background-color: #3a3a3c!important; */
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
}
@media only screen and (min-width: 300px) and (max-width: 425px) {
    .dialog-box-image-upload button {
        float: right;
        font-size: 16px;
        position: relative;
        /* right: 52px; */
    }
    .dialog-box-image-upload {
        width: 342px;
    }
}
@media only screen and (min-width:426px) and (max-width: 767px) {
    .dialog-box-image-upload button {
        float: right;
        font-size: 16px;
        position: relative;
        /* right: 52px; */
    }
    .dialog-box-image-upload {
        width: 342px;
    }
}

.browse-link {
   display: block;
   padding: 4px;
    border:  2px solid #5b89d7;
    width: 80px;
    height: 35px;
    margin: auto;
    margin-top: 20px;
    border-radius: 4px;
    color:#143773 ;
    cursor: pointer;
}
.filepond--root .filepond--drop-label{
    /* background: #d8d6d6; */
    height: auto;
    z-index: 9;
    border-radius: 4px;
    border:  2px ;
    border-style: solid;
    border-bottom: none;
    border-color: transparent;
}
.filepond--root .filepond--list-scroller{
    background: #fff;
}
.filepond--list-scroller .filepond--list  {
    background: #fff;
}
.or{
    padding-top: 15px;
}
.filepond--panel .filepond--panel-root{
    border-radius: 4px;
    border:  2px ;
    border-style: solid;
    border-color: #a5a5a5;
    background: #d8d6d6;
}
.dragimg::before{
    content:url(../../../Assets/Img/upload.png);
    height: 32px;
    width: 32px;
    margin: auto;
    display: block;
    margin-bottom: 5px;
}
.dragimg{
    padding-top: 10px;
}
