.home-footer {
    background-color: #2992b9;
}
.footerposition {
    position: absolute;
    width: 100%;
    display: block;
}
.home-footer .container {
    padding: 2.5rem 0px 4rem 0px;
}
.home-footer .footer-content h4 {
    color: #ffffff;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
}
.home-footer .leftColor-separator-small {
    background: #565656;
}
.leftColor-separator-small {
    height: 1px;
    position: relative;
    margin-top: 12px;
    background: #ebebeb;
}
.leftColor-separator-small:after {
    background: #fa7033;
    content: "";
    top: -1px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 45px;
    height: 3px;
    position: absolute;
    z-index: 1;
}
.home-footer .list {
    margin: 1em 0;
    padding: 0;
}
.home-footer .list .list-item {
    display: inline-flex;
    table-layout: fixed;
    list-style-type: none;
    list-style-position: outside;
    padding: .21428571em 0;
    line-height: 1.14285714em;
    padding-bottom: 10px;
    width: 100%;
}
.home-footer .list .list-item svg, .property-address .p-date svg {
    color: #ffffff;
}
.home-footer .list .list-content {    
    display: table-cell;
    padding: 0 0 0 10px;
    vertical-align: top;
    color: #ffffff;
    font-size: 13px;
    line-height: 16px;
}
.socialicon {
    margin: 30px 0px 5px;
}
.socialicon a {
font-size: 18px;
margin-right: 15px;
color: #ffffff;
cursor: pointer;
}
.socialicon a:hover, .socialicon a:active, .socialicon a:focus {
    color: #565656;
}
.quicklinks ul {
    padding: 0px 15px ;
    color: #ffffff;
}
.quicklinks ul li {
    padding-bottom: 4px;
}
.quicklinks ul li a, .newsletter p {
    font-size: 13px;
    color: #ffffff;
}
.contact-footer .list-content a {
    color: #ffffff;
    cursor: pointer;
}
.contact-footer .list-content a:focus, .contact-footer .list-content a:active, .contact-footer .list-content a:hover {
    color: #565656;
    text-decoration: none;
    cursor: pointer;
}
.quicklinks ul li a:hover, .quicklinks ul li a:focus, .quicklinks ul li a:active {
    color: #565656;
    text-decoration: none;
}
.new-properties {
    padding: 0 5px 7px;
    border-bottom: 1px solid #565656;
    margin-bottom: 10px;
    margin-top: 12px;
}
.new-properties a {
    display: flex;
    margin: 0 ;
    width: 100%;
    min-height: 0;
    background: 0 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    transition: box-shadow .1s ease;
}
.imgefooter {
    position: relative;
    flex: 0 0 auto;
    display: block;
    float: none;
    margin: 3px 2px 3px;
    background-color: #fff;
    border-radius: 2px;
    padding: 0;
    width: 80px;
    height: 50px;
    font-size: .85714286rem;
}
.imgefooter img {
    display: block;
    width: 100%;
    max-width: 100%;
    height:100%;
    border-radius: .125rem;
    border: none;
}
.property-address {
    min-width: 0;
    width: auto;
    display: block;
    margin-left: 0;
    align-self: top;
    padding-left: 1.5em;
}
.property-address .p-address, .property-address .p-date span {
    font-size: 13px;
    color: #ffffff;
    font-weight: 500;
}
.p-date {
    display: block;
    position: relative;
    background: 0 0;
    margin: 3px 0 0;
    width: 100%;
    padding: 0;
    top: 0;
    left: 0;
    color: rgba(0,0,0,.4);
    box-shadow: none;
    transition: color .1s ease;
    border-top: none;
}
.property-address .p-date span {
    padding-left: 5px;
    font-weight: 600;
}
.new-properties:hover {
    box-shadow: 0 0 6px 0 rgba(0,0,0,.25);
}
.new-properties a:hover {
    text-decoration: none;
}
.newsletter button {
    border-radius: .28571429rem;
    height: 38px;
    padding-top: .78571429em;
    padding-bottom: .78571429em;
    margin: 0;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    background-color: #fa7033;
    border: none;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
    border-left-color: transparent!important;
}
.newsletter input {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    border-right-color: transparent!important;
    margin: 0;
    outline: 0;
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 1em;
    /* color: #bbbbbb; */
    background:#1e6e8c;
    border: 1px solid rgb(30 110 140 / 58%);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    box-shadow: inset 0 0 0 0 transparent;
    width: 100%;
    vertical-align: top;
}
.newsletter input::placeholder {
    color: #979797;
}
.newsletter button:hover {
    background-color: #ff6724!important;
}
.newsletter button:focus, .newsletter button:active {
    background-color: #f54f05!important;
    color: #fff!important;
    text-shadow: none;
    box-shadow: none!important;
    outline: none!important;
}
.ui.input {
    position: relative;
    font-weight: 400;
    font-style: normal;
    display: inline-flex;
    color: rgba(0,0,0,.87);
    width: 100%;
}
.footer-newswidth {
    position: relative;
    max-width: 100%;
}
.nofound {
    font-size: 13px;
    color: rgb(255 255 255 / 71%);
    margin-top: 15px;
    display: block;
}
/*-- copy right ---*/
.copyright {
    background-color:  rgb(30 110 140);
}
.copyright .footer-bottom, .copyright .footer-bottom p  { 
    padding-top: 1em;
    padding-bottom: 1em;
}
.copyright .col-md-7, .copyright .col-md-5 {
    padding: 0px;
}
.copyright .footer-bottom p, .footer_right p a {
    font-size:13px;
    margin-bottom: 0px;
    color:#ffffff;
    text-align: center;
}
.copyright .footer_right{
    text-align: right;
}
@media only screen and (min-width: 280px) and (max-width: 720px) {
    .home-footer .container {
        padding: 30px 20px;
    }
    .imgefooter {
        margin: 0px 5px;
    }
    .copyright .footer-bottom, .copyright .footer-bottom p  { 
        padding: 15px 10px;
    }
    .home-footer .list .list-item {
        width: 100%;
    }
    .home-footer .footer-content.row .col-sm-3 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
}
@media only screen and (min-width: 721px) and (max-width: 745px){
    .home-footer .list .list-item {
        width: 100%;
    }
    .home-footer .footer-content.row .col-sm-3 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .home-footer .container {
        max-width: 628px!important;
    }
}
@media only screen and (min-width: 746px) and (max-width: 767px){
    .home-footer .list .list-item {
        width: 100%;
    }
    .home-footer .footer-content.row .col-sm-3 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .home-footer .container {
        max-width: 540px!important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 996px){
    .imgefooter {
        width: 40px;
        height: 33px;
    }
    .property-address {
        padding-left: 1em;
    }
    .footer-newswidth {
        max-width: 80%;
    }
}
@media only screen and (min-width: 501px) and (max-width: 540px){
    .home-footer .container {
        padding: 30px 35px!important;
    }
    .home-footer .list .list-item {
        display: flex;
    }
    .home-footer .footer-content.row .col-sm-3 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
}
.font-13 {
    font-size: 13px;
    cursor: pointer;
}
.font-13:hover {
    color:#565656 ;
}
.newsletters{
    color: #fff;
    text-align: center;
}
.newsletters h2{
    text-transform: uppercase;
}
.newsletters p{
    font-size: 1rem;
}
.newsletters .reactHubspotForms{
    background: #fff;
    padding: 15px 15px 0px 15px;
    margin-bottom: 25px;
}
.newsletters .reactHubspotForms iframe{
    width: 100% !important;
}
.newsletters .contact-inline{
    margin-bottom: 25px;
}