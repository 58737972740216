.oopspagenotfound {
    min-height: calc(100vh - 400px);
    background-color: #ffffff;
    text-align: center;
    padding: 20px 0px 60px;
}
.oopspagenotfound .row {
    width: 100%;
    margin: auto;
}
.oopspagenotfound .row div {
    margin: auto;
}
.oopspagenotfound p {
    font-size: 35px;
    opacity: .50;
    margin: 15px 0px;
}
.oopsimg {
    width: 347px;
    text-align: center;
    margin: auto;
}
.oopspagenotfound a {
    padding: 10px 20px;
    border: 1px solid #6e6f72;
    border-radius: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #6e6f72;
    margin-top: 15px;
}
.oopspagenotfound a:hover {
    text-decoration: none;
}