.user-management-form .row {
    margin: auto;
}
.user-management-form form .form-group {
    width: 100%;
}
.user-management-form form .form-group .form-control {
    height: calc(1.5em + .65rem + 2px)!important;
    padding: 5px;
    border-radius: 2px;
    font-size: 14px;
}
.user-management-form button.reset-btn {
    margin-right: 10px;
}
.user-management-form button.save-btn {
    margin-right: 0px;
}
.user-management-form form .form-group .invalid-feedback {
    position: absolute;
    margin:0px;
}
button.change-password {
    background-color: #fff!important;
    color: #212121!important;
    border: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 500;
}
button.change-password span:focus, button.change-password span:active, button.change-password span:hover {
    background-color: #fff!important;
    color: #25a0ce!important;
    border: 0px!important;
    outline: 0px!important;
    box-shadow: none!important;
}
button.change-password:focus, button.change-password:active, button.change-password:hover {
    background-color: #fff!important;
    border: 0px!important;
    outline: 0px!important;
    box-shadow: none!important;
}
button.change-password span {
    color: #2992b9;
    text-decoration: underline;
    padding: 0 7px;
    font-weight: 400;
}
.edit-password {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}
.margin-top-37 {
    margin-top: 37px!important;
}
.error-block-48 {
    top: 48px;
}
.account-password .form-group .invalid-feedback {
    position: initial!important;
    margin-bottom: -12px!important;
    margin-top: 5px!important;
}
.posistion-l-20 {
    position: relative;
    left: -20px;
}