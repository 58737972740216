.property-details {
    min-height: calc(100vh - 400px);
    background-color: #ffffff;
}
.table-property-tag table tbody tr td {
    border: 0px;
    padding: 15px 11px;
    vertical-align: middle;
}
.table-property-tag table tbody tr td.second-td-p {
    padding: 9px 11px!important;
}
.second-td-p h3 {
    color: #555759;
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0px;
    font-weight: 600;
    line-height: 1.28571429em;
    text-transform: none;
    font-family: 'Lato', sans-serif!important;
}
.second-td-p span {
    font-size: 14.3px;
    white-space: nowrap;
    color: rgba(0,0,0,.87);
    margin: 0px 15px 0px 0px;
}
.table-property-tag table {
    margin-bottom: 0px;
}
.table-property-tag {
    border-bottom: 1px solid rgb(225 225 225);
    padding: 10px 106px 5px;
    position: sticky;
    top: 0px;
    z-index: 11;
    background-color: #fff;
}
.property-toptag {
    background-color: #565656;
    border-color: #565656;
    margin: 2px 10px 2px 0px;
    width: 116px;
    text-align: center;
    color: #fff!important;
    font-size: 12px;
    display: inline-block;
    line-height: 1;
    border-radius: 5px;
    padding: 7px 9px;
    vertical-align: initial;
    font-weight: 600;
}
.property-toptagbluetag-ss {
    background-color: #2992b9!important;
    border-color: #2992b9!important;
    margin: 2px 10px 2px 0px;
    width: 116px;
    text-align: center;
    color: #fff!important;
    font-size: 12px;
    display: inline-block;
    line-height: 1;
    border-radius: 5px;
    padding: 7px 9px;
    vertical-align: initial;
    font-weight: 600;
}
.property-toptagorangetag-s {
    background-color:#e19503!important ;
    border-color: #e19503!important;
    margin: 2px 10px 2px 0px;
    width: 116px;
    text-align: center;
    color: #fff!important;
    font-size: 12px;
    display: inline-block;
    line-height: 1;
    border-radius: 5px;
    padding: 7px 9px;
    vertical-align: initial;
    font-weight: 600;
}
.calculator-dropdown h3 {
    margin-bottom: 0px;
    display: inline-flex;
    margin-top: 5px;
}
.property-details .calculator-row .col-sm-6:first-child {
    padding-left: 0px!important;
}
.calculator-dropdown .calculator-icon {
    font-size: 1.32rem;
    color: #2992b9!important;
    cursor: pointer;
    transition: opacity .1s ease;
}
.dropdowncalculator {
    margin-right: 4px;
    color: #2992b9!important;
    cursor: pointer;
}
button.calculatorbutton {
    background-color: transparent;
    border: none;
    padding: 0px;
    outline: none;
    box-shadow: none;
}
button.calculatorbutton:hover, button.calculatorbutton:focus, button.calculatorbutton:active {
    background-color: transparent!important;
    border: none!important;
    padding: 0px;
    outline: none!important;
    box-shadow: none!important;
}
button.calculatorbutton:not(:disabled):not(.disabled):active {
    border: none!important;
}
.width-236 {
    width: 23.5%;
}
.table-property-tag table tbody tr td.calculator-dropdown {
    /* width: 16%!important; */
    padding-right: 3px!important;
    text-align: right;
}
.calculator-price {
    color: #555759;
    vertical-align: middle;
    padding-left: .55rem;
    font-size: 18px;
    font-weight: 700;
    margin-top: 1px;
    font-family: 'Lato', sans-serif!important;
}
.calculator-dropdown .loancalculator {
    /* position: absolute; */
    width: 100%;
    left: 0px;
    top: 62px;
    background-color: rgb(244, 245, 249);
    border-bottom: 1px solid rgba(34,36,38,.1);
    padding: 0px;
    z-index: 1;
}
.calculator-dropdown-new .loancalculator {
    background-color: rgb(244 245 249)!important;
    border-bottom: 1px solid rgba(34,36,38,.1)!important;
    padding-top: 0px;
}
.calculator-dropdown-new.col-md-12 {
    padding: 0px!important;
    background-color: rgb(244, 245, 249);
    border-bottom: 1px solid rgba(34,36,38,.1);
    display: contents;
}
.calculator-dropdown-new .loancalculator h3, .calculator-dropdown-new .loancalculator .leftColor-separator {
    display: none;
}
.calculator-dropdown-new .loancalculator .container {
    padding: 0px;
}
.calculator-dropdown-new .loancalculator .row {
    width: 100%;
    margin: auto;
}
.calculator-dropdown-new .calculator-box1 .calculator-fields1 input, .calculator-dropdown-new .calculator-box1 .calculator-fields2 input {
    font-size: 14px;
    padding: 9.5px 14px;
}
.calculator-dropdown-new .calculator-box1 div.cal-button-left, .calculator-dropdown-new .calculator-box1 div.cal-button-right {
    font-size: 14px;
    padding: 12px;
}
.calculator-dropdown-new .calculator-form .row.calculator-row {
    padding-bottom: 7px;
}
/*-- container-property --*/
.container-property {
    padding: 15px 80px 15px 106px!important;
    background-color: #ffffff;
}
.container-property .row {
    width: 100%;
    margin: auto;
}
.property-heading {
    color: #555759;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;
    font-family: 'Lato', sans-serif!important;
}
.property-heading span {
    color: #555759;
    font-size: 14px;
    font-weight: 600;
    padding-left: 6px;
    margin-bottom: 14px;
    font-family: 'Lato', sans-serif!important;
}
.table-propertydetails table tbody tr td, .table-propertydetails-condition table tbody tr td {
    font-size: 13px;
    padding: 10px;
    border-right: none;
}
.table-propertydetails table tbody tr td a, .table-propertydetails-condition table tbody tr td a {
    color: #555759;
    /* font-style: italic; */
    text-decoration: underline;
}
.table-propertydetails table tbody tr td a:hover, .table-propertydetails-condition table tbody tr td a:hover {
    color: #fa7033;
}
.table-propertydetails table, .table-propertydetails-condition table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: none;
    border-radius: .28571429rem;
    text-align: left;
    color: rgba(0,0,0,.87);
    border-collapse: initial;
    border-spacing: 0;
}
.table-propertydetails table tbody tr td:last-child, .table-propertydetails-condition table tbody tr td:last-child {
text-align: right;
}
.table-propertydetails table tbody tr td, .table-propertydetails-condition table tbody tr td {
    border-top: none;
    border-left: none;
}
.table-propertydetails table tbody tr:last-child td, .table-propertydetails-condition table tbody tr:last-child td {
    border-bottom: none;
}
.market-insighs {
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: none;
    border-radius: .28571429rem;
}
.powedbystortack {
    position: absolute;
    font-size: 11px;
    margin-bottom: 0px;
    top: 26px;
    right: 16px;
    font-style: italic;
}
.Property-contact-box {
    position: relative;
    background: #f3f4f5;
    color: rgba(0,0,0,.6);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    margin: 0px 0 0px;
    padding: 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);
}
.Property-contact-box p {
    font-weight: bolder;
    color: #565656;
    font-size: 13px;
}
.Property-contact-box form .form-group {
    width: 100%;
}
.Property-contact-box form .form-group input, .Property-contact-box form .form-group textarea {
    font-family: 'Lato', sans-serif!important;
    margin: 0;
    outline: 0;
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 13px;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: 5px;
    box-shadow: inset 0 0 0 0 transparent;
}
.Property-contact-box form .form-group input::placeholder {
    color: #dedede;
}
.Property-contact-box form .form-group textarea::placeholder {
    color: #dedede;
}
.Property-contact-box form .form-group input:hover, .Property-contact-box form .form-group input:focus, .Property-contact-box form .form-group input:active,
.Property-contact-box form .form-group textarea:hover, .Property-contact-box form .form-group textarea:focus, .Property-contact-box form .form-group textarea:active
{
    color: rgba(0,0,0,.95);
    border-color: #85b7d9;
    border-radius: .28571429rem;
    background: #fff;
    box-shadow: inset 0 0 0 0 rgba(34,36,38,.35);
}
.Property-contact-box form .form-group textarea {
    height: 71px;
}
.Property-contact-box form .form-group .invalid-feedback {
    position: absolute;
    margin-top: 0px;
    font-size: 10px;
}
.p-contant-checkbox .form-check {
    position: absolute;
    left: 0px;
    display: inline-block;
    backface-visibility: hidden;
    outline: 0;
    vertical-align: initial;
    font-style: normal;
    min-height: 17px;
    font-size: 1rem;
    line-height: 17px;
    min-width: 17px;
}
.p-contant-checkbox label {
    cursor: pointer;
    position: relative;
    display: block;
    padding-left: 22px;
    outline: 0;
    color: #565656;
    font-size: 13px;
    margin-bottom: 7px;
}
button.send-contactbutton {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    background-color: #2992b9;
    width: 100%;
    vertical-align: initial;
    color: #ffffff;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-family: 'Lato', sans-serif!important;
    font-weight: 600;
    font-size: 14px;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
}
button.send-contactbutton:hover, button.send-contactbutton:focus, button.send-contactbutton:active {
    background-color: #25a0ce!important;
    box-shadow: none!important;
    outline: 0px!important;
}
button.greenbutton {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    background-color: #8ec140;
    width: 100%;
    vertical-align: initial;
    color: #ffffff;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-family: 'Lato', sans-serif!important;
    font-weight: 600;
    font-size: 14px;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
}
button.greenbutton:hover, button.greenbutton:focus, button.greenbutton:active {
    background-color: #98d247;
    color: #fff;
    text-shadow: none;
}
/*-- slider--*/
.col-md-6.property_img {
    padding-left: 0px;
    padding-right: 30px;
    margin-bottom: 40px;
}
.property-details .col-md-9.property_img{
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 25px;
}
.property-details .col-md-12.property_img{
    margin-bottom: 25px;
}
.property_img .carousel-status {
    display: none;
}
.property_img .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #3797dd;
}
.property_img .carousel .control-next.control-arrow:before {
    border-left: 8px solid #3797dd;
}
.property_img .carousel .thumbs {
    display: none;
}
.property_img .selected {
    border: 1px solid #dee2e6;
}
.image-carousel-size ul.control-dots {
    display: none;
}
.listing-img-resize {
    background-color: #fff;
    height: 350px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    /* margin-bottom: 32px; */
}
.propertyview-hotlist {
    padding: 0px!important;
}
.propertyview-hotlist .home-property .content-property .col-sm-6 span p svg {
    margin-right: 3px;
}
.listing-img-resize .carousel .slide img {
    object-fit: cover;
}
.listing-img-resize img {
    object-fit: cover;
    width: auto !important;
    height: auto;
    margin: 0 auto;
    border-radius: 5px;
    /* border: 1px solid #ddd;
    box-shadow: 0px 0px 4px #ddd; */
}
.property_img .carousel .slide {
    background: #fff!important;
    padding: 0px;
    border-radius: 5px;
}
.property_img .carousel.carousel-slider {
    box-shadow: 0px 0px 4px #ddd;
}
.property_img .carousel .thumbs-wrapper {
    display: none!important;
}
/*-- description --*/
.property-v-description {
    margin-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
}
.col-md-12.property-v-description {
    padding: 0px!important;
}
.property-v-description pre {
    padding: 0 0 0 3px;
    margin: 15px 0;
    font-size: 13px;
    /* height: auto;
    max-height: 300px;
    overflow-y: auto; */
    font-family: 'Roboto';
    word-break: break-word;
    white-space: pre-wrap;
    color: #555759;
}
.property-v-description p.myBox3 span {
    font-size: 14px!important;
}
.description-heading {
    padding: 0;
    font-family: 'Lato', sans-serif!important;
    font-weight: 700;
    line-height: 1.28571429em;
    text-transform: none;
    font-size: 24px;
    color: #555759;
}
/*---map-property ---*/
.map-property {
    background: #f3f4f5;
    color: rgba(0,0,0,.6);
    padding: 15px;
}
.row.property-serach-map {
    width: 100%;
    margin: auto;
}
body .property-map-size {
    width: 100% !important;
    /* height: 57%; */
    margin: 0px auto 5px;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    padding: 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15)
}
.property-map-size2 {
    margin: 0px auto 20px;
    width: 85%;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    margin-top: 1rem;
    padding: 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);
}
.streetviewmap {
    position: relative;
    z-index: 0;
    background: #fff!important;
}
.map-property a {
     font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
    color: #000000de;
    font-weight: 400;
    font-size: 14px;
    color: #000000de!important;
    margin-right: 20px;
    margin-top: 37px;
    padding-bottom: 5px;
    text-decoration: none;
    cursor: pointer;
}
.map-property a.active {
    border-bottom: 2px solid #fa7033;
    font-weight: 400!important;
    text-decoration: none;
}
.map-property a:hover {
    border-bottom: 2px solid #cccccc;
    text-decoration: none;
}
/*-- condition --*/
.table-property-tag-condition {
    border-bottom: 1px solid rgb(225 225 225);
    padding: 0px 15px;
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #fff;
}
.table-property-tag-condition table td, table th {
    border: none;
    vertical-align: middle;
}
.container-property-condition .row {
    width: 100%;
    margin: auto;
}
.table-property-tag-condition table {
    margin-bottom: 0px;
}
.table-propertydetails-condition.col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
}
.table-property-tag-condition .calculator-dropdown .loancalculator {
    top: 68px;
    padding: 1px 25px!important;
}
.container-property-condition {
    padding: 25px 25px 25px 25px!important;
}
.width-236-condition {
    width: 23.5%;
}
/* Box styles */
.myBox3 {
    height: auto;
    overflow-y: auto;
    max-height: 292px;
    }
    
    /* Scrollbar styles */
    .myBox3::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    }
    
    .myBox3::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
    }
    
    .myBox3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;  
    }
    
    .myBox3::-webkit-scrollbar-thumb:hover {
    background: #979797;  
    }
.tag-wishlist-propertyv {
        position: absolute;
        right: 40px;
        display: inline-flex;
        top: 10px;
        margin: 0px;
        z-index: 10;
}
.novendorimg {
    height: 358px!important;
}
.tag-wishlist-propertyv button {
    padding: 0px;
    outline: none!important;
    box-shadow: none!important;
    border: none!important;
    background-color: #ffffff!important;
    width: 33px!important;
    height: 33px!important;
    border-radius: 16px!important;
}
.tag-wishlist-propertyv button img {
    box-shadow: none!important;
    border: none!important;
    background-color: transparent!important;
    width: 23px!important;
    height: 23px!important;
    position: relative!important;
    left: .5px!important;
    top: 1.5px!important;
}
.tag-wishlist-propertyv button:focus, .tag-wishlist-propertyv button:active, .tag-wishlist-propertyv button:hover {
    box-shadow: none!important;
    outline: none!important;
    border: none!important;
    background-color: #ffffff!important;
    width: 33px!important;
    height: 33px!important;
    border-radius: 16px!important;
    padding: 0px!important;
}
.tag-wishlist-propertyv button:not(:disabled):not(.disabled):active {
    box-shadow: none!important;
    outline: none!important;
    border: none!important;
    background-color: #ffffff!important;
    width: 33px!important;
    height: 33px!important;
    border-radius: 16px!important;
    padding: 0px!important;
}
body .propertydetailsads {
    width: 100%;
    height: auto !important;
    
}
body .lisiting-page-mobile .propertydetailsads {
    width: 100%;
    height: auto !important;
    
}

.propertydetailsads img {
    object-fit: fill;
    border-radius: 3px;
}
.map-property .streetviewmap .gm-iv-address-link a {
    color: #5c8dcc!important;
    text-decoration: none;
    font-size: 11px!important;
}
.map-property .streetviewmap .gmnoprint a, .map-property .streetviewmap .gm-style-cc a {
    color: #ffffff!important;
}
/*--- media ---*/
@media only screen and (min-width: 768px) and (max-width: 995px){
    body .markets-columns .col-market-custom {
        flex: 0 0 100%;
        max-width: 100%;
    }
    body .insight-details a.view-property-market{
        padding: 10px 12px;
    }
}
@media only screen and (min-width: 1301px) and (max-width: 1302px){
    body .markets-columns .col-market-custom {
        flex: 0 0 47%;
        max-width: 47%;
    }
}
@media only screen and (min-width: 1250px) and (max-width: 1272px){
    body .markets-columns .col-market-custom {
        flex: 0 0 47%;
        max-width: 47%;
    }
}
@media only screen and (min-width: 996px) and (max-width: 1209px){
    body .markets-columns .col-market-custom {
        flex: 0 0 47%;
        max-width: 47%;
    }
}
@media only screen and (min-width: 280px) and (max-width: 550px){
    .col-md-9.property_img {
        padding-left: 15px!important;
        padding-right: 15px!important;
    }
    .table-property-tag table tbody tr td {
        border: 0px;
        padding: 15px 15px;
        vertical-align: middle;
        width: 100%!important;
        display: table!important;
        margin-top: 10px!important;
        margin-bottom: 10px!important;
    }
    .property-details .loancalculator {
        min-height: auto;
    }
    .second-td-p span {
        white-space: break-spaces!important;
    }
}
@media only screen and (min-width: 200px) and (max-width: 280px){
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
    .novendorimg {
    height: 160px!important;
}
}
@media only screen and (min-width: 280px) and (max-width: 300px){
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
}
@media only screen and (min-width: 301px) and (max-width: 320px){
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
    .novendorimg {
        height: 180px!important;
    }
}
@media only screen and (min-width: 321px) and (max-width: 360px){
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
    .novendorimg {
        height: 200px!important;
    }
}
@media only screen and (min-width: 361px) and (max-width: 399px){
    .listing-img-resize {
        height: 222px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
}
@media only screen and (min-width: 400px) and (max-width: 411px){
    .listing-img-resize {
        height: 237px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
}
@media only screen and (min-width: 412px) and (max-width: 425px){
    .listing-img-resize {
        height: 252px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
    .novendorimg {
        height: 239px!important;
    }
}
@media only screen and (min-width: 426px) and (max-width: 450px){
    .listing-img-resize {
        height: 247px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
    }
@media only screen and (min-width: 480px) and (max-width: 500px){
    .listing-img-resize {
        height: 282px!important;
    }
}
@media only screen and (min-width: 501px) and (max-width: 560px){
    .listing-img-resize {
        height: 282px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
    .novendorimg  {
        height: 313px!important;
    }
}
@media only screen and (min-width: 561px) and (max-width: 599px){
    .listing-img-resize {
        height: 300px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 302.6px!important; */
        height: 400px!important;
    }
}
@media only screen and (min-width: 600px) and (max-width: 767px){
    .listing-img-resize {
        height: 416px!important;
    }
    .col-md-6.property_img{
        padding: 0 20px!important;
    }
    .padding-left-remove.col-sm-9 {
        padding: 0 15px!important;
    }
    .property-details .container,.property-details .container-sm {
        max-width: 742px;
    }
    .property-map-size2 .streetviewmap {
        /* width: 700px!important; */
        height: 400px!important;
    }
    .home-property .carousel-root {
        height: 148px;
    }
    .property-serach-map .property-map-size .mobile-mapview {
        height: 350px!important;
    }
    .property-details .container-property {
        padding: 25px 50px 25px 50px!important;
        background-color: #ffffff;
    }
}
@media only screen and (min-width: 280px) and (max-width: 767px){
    .container-property {
        padding: 25px 15px 25px 15px!important;
    }
    .mobile-mapview {
        height: 400px!important;
    }
    .property-details {
        position: relative;
        top: 11px;
    }
    .second-td-p span {
        white-space: break-spaces!important;
    }
    .table-property-tag {
        padding: 0px 15px;
        position: unset!important;
    }
    .table-property-tag table tr td {
        display: block!important;
        width: auto!important;
    }
    .table-property-tag table tbody tr td.calculator-dropdown {
        width: 100%!important;
    }
    .col-md-6.property_img {
        padding-left: 0px;
        padding-right: 0px;
    }
    .listing-img-resize {
        height: 180px;
    }
    .container-property .padding-left-remove {
        padding-left: 0px;
        padding-right: 0px;
    }
    .table-propertydetails.col-md-6 {
        padding: 0 0px;
    }
    .container-property .col-sm-3 {
        padding: 0px 0px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 302.6px; */
        height: 400px;
    }
    .propertyview-hotlist .row {
        width: 100%!important;
        margin: auto!important;
    }
    .propertydetailsads img {
        object-fit: contain!important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 996px){
    .container-property {
        padding: 25px 15px 25px 15px!important;
    }
    .mobile-mapview {
        height: 400px!important;
    }
    .table-property-tag {
        padding: 0px 15px;
        position: unset!important;
    }
    .table-property-tag table tr td {
        display: block!important;
        width: auto!important;
        padding: 7px 11px 0px;
    }
    .table-property-tag table tr td:last-child {
        padding-bottom: 10px;
    }
    .table-property-tag table tbody tr td.calculator-dropdown {
        width: 100%!important;
    }
    .novendorimg {
        height: 220px!important;
    }
    .property-v-description pre {
        max-height: 180px!important;
    }
    .container-property .row .col-sm-9 .col-md-6 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .col-md-6.property_img {
        padding-left: 15px!important;
    }
    .container-property .row .col-sm-9 .col-md-6.padding-left-remove {
        padding-left: 15px!important;
    }
    .listing-img-resize {
        height: 280px;
    }
    .propertydetailsads {
        width: 100%;
        height: 87%;
    }
    .streetviewmap {
        /* width: 654px; */
        height: 480px;
    }
    .property-map-size {
        width: 90%!important;
    }
}
@media only screen and (min-width: 997px) and (max-width: 1050px){
    .table-property-tag {
        padding: 0 15px!important;
    }
    .container-property {
    padding: 25px 30px 25px 30px!important;
    background-color: #ffffff;
}
.novendorimg {
    height: 295px!important;
}
.property-v-description pre {
    max-height: 250px!important;
}
.mobile-mapview {
    height: 400px!important;
}
.property-map-size2 .streetviewmap {
    /* width: 900px; */
    height: 400px;
}
}
@media only screen and (min-width: 1051px) and (max-width: 1200px){
    .container-property {
        padding: 25px 60px 25px 62px!important;
        background-color: #ffffff;
    }
    .table-property-tag {
        padding: 0px 52px;
    }
    .width-236 {
        width: 28.5%;
    }
    .img-home-property {
        width: 100%!important;
    }
    .property-map-size,  .property-map-size2 {
        width: 90%!important;
    }
    .property-map-size .mobile-mapview {
        height: 400px!important;
    }
    .streetviewmap {
        /* width: 899.47px!important; */
        height: 400px!important;
    }
}
@media only screen and (min-width: 1210px) and (max-width: 1249px){
    .table-property-tag {
        padding: 0px 45px;
    }
    .container-property {
        padding: 25px 38px 25px 55px!important;
        background-color: #ffffff;
    }
    .property-map-size {
        width: 91%;
    }
    .property-map-size2 {
        width: 91%;
    }
}
@media only screen and (min-width: 1250px) and (max-width: 1300px){
    .property-toptagorangetag-s {
        width: 113px!important;
    }
    .container-property {
        padding: 25px 79px 25px 77px!important;
    }
    .property-map-size {
        width: 88%!important;
    }
    .table-property-tag {
        padding: 0px 68px!important;
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1450px){
    .property-serach-map .property-map-size .mobile-mapview {
        height: 480px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 1196.47px!important; */
        height: 480px!important;
    }
}
@media only screen and (min-width: 1451px) and (max-width: 1520px){
    .property-serach-map .property-map-size .mobile-mapview {
        height: 480px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 1257.47px!important; */
        height: 480px!important;
}
.novendorimg {
    height: 407px!important;
}
.property-v-description pre {
    max-height: 357px!important;
}
.home-property .carousel-root {
    height: 237px!important;
}
.calculator-dropdown-new .container, .propertyview-hotlist.container {
    max-width: 1275px!important;
}
.listing-img-resize {
    height: 400px;
}
.propertydetailsads {
    height: 400px;
}
}
@media only screen and (min-width: 1521px) and (max-width: 1600px){
    .property-serach-map .property-map-size .mobile-mapview {
        height: 480px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 1282.47px!important; */
        height: 460px!important;
}
.property-map-size {
    width: 86%;
}
.map-property .container.mapcontainer {
    max-width: 1329px!important;
}
.novendorimg {
    height: 350px!important;
}
.property-v-description pre {
    max-height: 310px!important;
}
.calculator-dropdown-new .container, .propertyview-hotlist.container {
    width: 1317px!important;
    max-width: 1317px!important;
}
.listing-img-resize {
    height: 400px;
}
.propertydetailsads {
    height: 400px;
}
}
@media only screen and (min-width: 1650px) and (max-width: 1710px){
    .novendorimg {
        height: 468px!important;
    }
    .property-v-description pre {
        max-height: 417px!important;
    }
    .property-serach-map .property-map-size .mobile-mapview {
        height: 480px!important;
    }
    .property-map-size2 .streetviewmap {
        /* width: 1315.47px!important; */
        height: 480px!important;
}
.calculator-dropdown-new .container, .propertyview-hotlist.container {
    width: 1472px!important;
    max-width: 1472px!important;
}
.home-property .img-home-property {
    width: 100%!important;
    /* height: 100%!important; */
}
body .propertyview-hotlist .img-home-property{
    width: 100% !important;
}
.home-property .carousel-root {
    height: 235px!important;
}
.listing-img-resize {
    height: 400px;
}
.propertydetailsads {
    height: 400px;
}
}
@media only screen and (min-width: 1680px) and (max-width: 1710px){
    .mapcontainer {
        max-width: 1500px!important;
    }
    .property-map-size {
        width: 88%;
    }
    .property-map-size2 {
        width: 88%;
    }
    .listing-img-resize {
        height: 400px;
    }
    .propertydetailsads {
        height: 400px;
    }
}
@media only screen and (min-width: 1790px) and (max-width: 1820px){
    .mapcontainer {
        max-width: 1600px!important;
    }
    .property-map-size {
        width: 88%;
    }
    .property-map-size2 {
        width: 88%;
    }
    .listing-img-resize {
        height: 400px;
    }
    .propertydetailsads {
        height: 400px;
    }
}
@media only screen and (min-width: 2030px) and (max-width: 2048px){
    .property-map-size {
        width: 90%;
    }
    .listing-img-resize {
        height: 450px;
    }
    .propertydetailsads {
        height: 450px;
    }
}
@media only screen and (min-width: 1916px) and (max-width: 1920px){
    .listing-img-resize {
        height: 428px;
    }
    .propertydetailsads {
        height: 428px;
    }
}
.no-streetview {
    background-color: #fff6f6;
    color: #9f3a38;
    position: relative;
    min-height: 1em;
    margin: 0 0 1em;
    padding: 0.8em 1.5em;
    line-height: 1.4285em;
    border-radius: .28571429rem;
    box-shadow: inset 0 0 0 1px #e0b4b4, 0 0 0 0 transparent;
}
.map-property .streetviewmap canvas {
    width: 100%!important;
}
a.buyreport-btn {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    background-color: #fa7033;
    /* width: 100%; */
    vertical-align: initial;
    color: #ffffff!important;
    margin: 0 .25em 8px 0;
    padding: .78571429em 1.5em;
    text-transform: uppercase;
    text-shadow: none;
    font-family: 'Lato', sans-serif!important;
    font-weight: 600;
    font-size: 14px;
    line-height: 1em;
    text-align: center;
    text-decoration: none!important;
    border-radius: .28571429rem;
}
a.buyreport-btn:hover {
    background-color: #fa7033;
    cursor: pointer;
}
.property-map-size .gm-style-mtc button,
.property-map-size2 .gm-style-mtc button {
    height: 36px!important;
    font-size: 15px!important;
}
.property-map-size .gm-style-mtc button:focus,
.property-map-size2 .gm-style-mtc button:focus {
    outline: 0!important;
}
.property-map-size .gm-style-mtc button ul,
.property-map-size2 .gm-style-mtc button ul {
    top: 35px!important;
}
.property-map-size .gm-style .gm-style-mtc div,
.property-map-size2 .gm-style .gm-style-mtc div {
    font-size: 15px!important;
}
.property-map-size .gm-style .gm-style-mtc label,
.property-map-size2 .gm-style .gm-style-mtc label {
margin-bottom: 0px!important;
margin-top: 6px!important;
}
.property-map-size .gm-style-mtc ul li span img,
.property-map-size2 .gm-style-mtc ul li span img {
    position: relative;
    top: -3px;
}
.property-map-size .gm-style-mtc ul li label,
.property-map-size2 .gm-style-mtc ul li label {
    font-size: 15px;
    margin-left: 3px;
}
.property-map-size button.gm-fullscreen-control,
.property-map-size2 button.gm-fullscreen-control {
    height: 35px!important;
    width: 35px!important;
}
.property-map-size button.gm-fullscreen-control img,
.property-map-size2 button.gm-fullscreen-control img {
    height: 16px!important;
    width: 16px!important;
}
.property-map-size .gm-svpc,
.property-map-size2 .gm-svpc {
    height: 40px!important;
    width: 40px!important;
}
.property-map-size .gm-svpc img,
.property-map-size2 .gm-svpc img {
    height: 27px!important;
    width: 27px!important;
}
.property-map-size button.gm-control-active img,
.property-map-size2 button.gm-control-active img {
    width: 15px!important;
    height: 15px!important;
}
/*--- faqpopup ---*/
.faqpopup .modal-content .modal-header .modal-title {
    font-size: 18px;
    font-weight: 600;
    color: #565656;
}
.faqpopup .modal-content .modal-header .close span {
    color: #fa7033;
}
.faqpopup .modal-content .modal-body p {
    font-size: 16px;
    color: #565656;
    text-align: justify;
}
/* .property_img .image-carousel-size .carousel-slider .slider-wrapper .slide {
    opacity: 0;
   visibility: hidden;
   transition: 0.3s linear;
}
.property_img .image-carousel-size .carousel-slider .slider-wrapper .slide.selected{
    opacity: 1;
    visibility: visible;
    transition: 0.3s linear;
} */
.powered-by{
    font-size: 11px;
    font-weight: normal;
    margin-left: 10px;
}
.powered-by a{
    font-size: 11px;
    color: #00b0ed !important;
    text-decoration: underline;
}
.powered-by a:hover{
    border-bottom: none;
    text-decoration: underline;
}
.map-property .mapcontainer.container{
    padding-left: 0px;
    padding-right: 0px;
}
.map-filter-by span.black{
    border: 1px solid #000;
    margin-right: 5px;
    border-radius: 5px;
}
.map-filter-by span.black.active{
    background: #000;
    color: #fff !important;
}
.map-filter-by span.red{
    border: 1px solid #f37864;
    margin-right: 5px;
    border-radius: 5px;
    color: #f37864 !important;
}
.map-filter-by span.red.active{
    background: #f37864;
    color: #fff !important;
}
.map-filter-by span.green{
    border: 1px solid #38a83c;
    margin-right: 5px;
    border-radius: 5px;
    color: #38a83c !important;
}
.map-filter-by span.green.active{
    background: #38a83c;
    color: #fff !important;
}
.map-filter-by span{
    font-size: 12px;
    padding-bottom: 0px;
    margin-top: 0px;
    padding: 3px 10px;
    cursor: auto !important;
    font-weight: normal;
}
.map-filter-by {
    text-align: right;
    margin-bottom: 10px;
}
.circle-count {
    /* background-color: #3d9ae3;
    color: #fff;
    position: absolute;
    top: 28px;
    border-radius: 50%;
    padding: 3px 0;
    font-size: 11px;
    font-weight: 500;
    margin-left: -10px;
    border: 2px solid #fff;
    width: 33px;
    height: 33px;
    text-align: center;
    line-height: 24px; */
    display: block;
    text-align: center;
    font-weight: bold;
    margin-top: 5px;
    color: #000;
}
.insight-details a.view-property-market{
    padding: 7px 15px 8px 15px;
    background: #fa7033;
    color: #fff !important;
    font-size: 14px;    
    border-radius: 3px;
    border-bottom: none !important;
    font-weight: normal;
}
.markets-columns .col-market-custom{
    background: #fff;
    border: 1px solid #dee2e5;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
.markets-columns .col-market-custom:last-child{
    margin-right: 0px;
}
.markets-columns{
    padding: 0px 5px;
}
.markets-columns .col-market-custom:hover{
    background: #ebebeb;
}
.markets-columns .col-market-custom .other-labels{
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 13px;
    color: rgba(0,0,0,.6);
}
.button-with-icon{
    display: inline-block;
    float: right;
}
.button-with-icon a:hover{
    border-bottom: none;
}
.property-taxes-periods{
    padding-top: 0px;
    padding-bottom: 0px;
}
.view-sample-report{
    background-color: #fa7033;
    color: #fff !important;
    text-decoration: none !important;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 3px;
    font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
}
.button-with-icon svg{
    font-size: 20px;
}
.propertyview-hotlist .img-home-property{
    height: 150px !important;
    background-size: 100% 100%;
    border-radius: 0px !important;
    width: 100% !important;
}
.propertyview-hotlist .tags-tss{
    right: 0px;
    left: 14px;
}
.propertyview-hotlist .content-property{
    width: 55%;
    display: block;
    float: left;
    border: none;
    border-radius: 0px;
}
.propertyview-hotlist .col-custom-padding{
    padding-right: 10px;
    padding-left: 10px;
}
.propertyview-hotlist .home-property{
    border: 1px solid #dddddd;
}
.propertyview-hotlist .home-property .content-property svg {
    margin-right: 3px;
}
@media only screen and (max-width: 1200px){
    .propertyview-hotlist .img-home-property{
        width: 100%;
    }
    .propertyview-hotlist .content-property{
        width: 100%;
    }
}
@media only screen and (max-width: 576px){
    body .img-home-property img{
        height: 100%;
    }
}
.map-property .mapcontainer{
    width: 85%;
    margin: 0 auto;
    max-width: initial;
}
.property-serach-map .map-filter-by{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}
.agent-contact .contact-heading{
    font-size: 18px;
    font-family: "Roboto"!important;
    margin-bottom: 12px;
}
.agent-contact label{
    font-weight: 500;
    margin-bottom: 0px;
}
.agent-contact{
    background: #f3f4f5;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    padding: 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);
    margin: 15px 0 1rem;
}
.agent-contact .label-name{
    color: rgba(0,0,0,.7);
    font-size: 13px;
    margin-bottom: 6px;
}
.agent-contact .label-name:last-child{
    margin-bottom: 0px;
}
@media only screen and (max-width: 767px){
    .propertydetailsads{
        margin-bottom: 25px;
    }
    body .listing-img-resize img{
        width: 100% !important;
    }
    .mobile-right-0{
        padding-right: 0px;
    }
    .map-property{
        margin-bottom: 20px;
    }
    .markets-columns .col-market-custom{
        flex: 0 0 48%;
        max-width: 48%;
    }
    body .marketdata-responsive{
        display: block;
    }
    .Property-contact-box{
        margin-top: 5px;
    }
}
@media only screen and (min-width: 768px){
    .agent-contact .first-width{
        flex: 0 0 45%;
        max-width: 45%;
    }
    .agent-contact .sec-width{
        flex: 0 0 55%;
        max-width: 55%;
    }
    .markets-columns .col-market-custom{
        flex: 0 0 48%;
        max-width: 48%;
    }
}
.marketview-platform-popup .modal-header{
    width: 100%;
    display: block;
    border-bottom: none;
    padding-bottom: 5px;
}
.faqpopup.marketview-platform-popup .modal-content .modal-header .modal-title{
    text-align: center;
    font-size: 20px;
}
.faqpopup.marketview-platform-popup .modal-content .modal-header .modal-title div{
    font-size: 16px;
}
.faqpopup.marketview-platform-popup .modal-body ul li{
    margin-bottom: 6px;
}
.faqpopup.marketview-platform-popup .modal-body ul{
    margin-bottom: 10px;
    color: #565656;
    margin-top: 5px;
    padding-left: 0px;
    list-style: none;
}
.faqpopup.marketview-platform-popup .modal-header .close{
    position: absolute;
    right: 5px;
    top: 0px;
    outline: none !important;
}
@media only screen and (min-width: 576px){
    .marketview-platform-popup.modal-dialog{
        max-width: 1150px;
    }
}
.insight-details .property-heading .events-none{
    font-size: 18px;
    padding-left: 0px;
}
.markets-columns .col-market-custom:nth-child(even){
    margin-right: 0px;
}
.insight-details .markets-columns{
    padding: 0px;
}
.insight-details .view-known{
    margin-top: 7px;
}
.insight-details .view-known ul{
    margin-top: 5px;
}
.insight-details a.view-property-market:hover{
    cursor: pointer;
    text-decoration: none;
}
.insight-details .button-with-icon a{
    cursor: pointer;
}
.only-lss-logo, .only-mvp-logo{
    vertical-align: middle;
    display: flex;
    align-items: center;
    width: 50%;
}
.only-lss-logo{
    border-right: 1px solid #8c8c8c;
}
.only-lss-logo img{
    margin: 0 20px 0 auto;
}
.only-mvp-logo img{
    margin: auto 0 0 20px;
}
.lss-mvp-logo{
    display: flex;
}
.marketview-platform-popup p{
    margin-bottom: 10px;
}
.marketview-platform-popup p.do-you-want{
    font-weight: 500;
    text-align: center !important;
}
.marketview-platform-popup .view-sample-report{
    margin-bottom: 10px;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
}
.marketdata-responsive{
    display: none;
}
.telescope-img{
    margin-right: 5px;
}
.real-market-data img{
    width: 100%;
}
.real-market-data .view-pricing-link{
    display: inline-block;
    margin-left: 10px;
    color: #fa7033;
    border: 1px solid;
    border-radius: 3px;
    padding: 7px 20px;
    text-decoration: none;
    font-size: 14px;
    font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
}
.real-market-data .compare-plans{
    font-size: 14px;
    text-decoration: underline;
    display: table;
    font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
}
.faqpopup.marketview-platform-popup .modal-body ul li::before{
    content: "\2713";
    margin-right: 7px;
    color: #fa7033;
    font-weight: 600;
    float: left;
    display: table;
}
.faqpopup.marketview-platform-popup .modal-body ul li span{
    display: table;
    line-height: 20px;
}
.real-market-data h3{
    font-weight: 600;
}
.real-market-data h5{
    font-weight: 600;
    margin-bottom: 15px;
}
.real-market-data.modal-body{
    padding: 20px 30px;
    padding-bottom: 40px;
}
.oopspropnotfound {
    min-height: calc(100vh - 590px);
    background-color: #ffffff;
    text-align: center;
    padding: 20px 0px 60px;
}
.oopspropnotfound .row {
    width: 100%;
    margin: auto;
}
.oopspropnotfound .row div {
    margin: auto;
}
.oopspropnotfound p {
    font-size: 35px;
    opacity: .50;
    margin: 15px 0px;
}
.oopspropnotfound a {
    padding: 10px 20px;
    border: 1px solid #6e6f72;
    border-radius: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #6e6f72;
    margin-top: 15px;
}
.oopspropnotfound a:hover {
    text-decoration: none;
}
.docbg{
    background: #f2f2f2cc;
    padding: 8px;
    margin-bottom: 20px;
}
.docbg ul{padding-left: 0px;}
.docbg ul li{ list-style: none; line-height: 25px;}