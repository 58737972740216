
.stepper-box .MuiStepIcon-root {
    width: 34px;
    height: 34px;
    color: #fff;
}
.stepper-box .MuiStepLabel-root .MuiStepLabel-alternativeLabel svg.MuiSvgIcon-root {
    width: 32px;
    height: 32px;
    background: #fa7033;
    border-radius: 50%;
    margin: 0;
}
.stepper-box .MuiStepIcon-completed-cx svg text {
    fill: #fff!important;
}
.stepper-box svg text.MuiStepIcon-text {
    font-size: 12px!important;
    font-weight: 600;
    fill: #b2b2b3;
}
.stepper-box .MuiStepConnector-active .MuiStepConnector-lineHorizontal, .stepper-box .MuiStepConnector-completed .MuiStepConnector-lineHorizontal, .landing-popup .MuiStepConnector-active .MuiStepConnector-lineHorizontal, .landing-popup .MuiStepConnector-completed .MuiStepConnector-lineHorizontal {
    border-color: #fa7033;
}
.stepper-box .MuiStepIcon-text {
    fill: #fff;
}
.stepper-box .Mui-disabled .MuiStepLabel-iconContainer svg.MuiSvgIcon-root {
    border: 2px solid #fa7033;
}
.stepper-box svg.MuiStepIcon-active, .stepper-box .MuiStepIcon-root.MuiStepIcon-completed  {
    color: #fa7033;
}
.stepper-box svg.MuiStepIcon-active text {
    font-size: .80rem;
    font-weight: 500;
    fill: #fff;
}
.stepper-box svg text.MuiStepIcon-text:active {
    fill: #fff;
}
.stepper-box svg.MuiStepIcon-active text {
    fill: #fff;
}
.stepper-box svg.MuiStepIcon-active {
    color: #fa7033;
}
.stepper-box .MuiStep-completed svg {
    color: #fa7033!important;
}
.stepper-box .MuiStep-completed svg text.MuiStepIcon-text {
    fill: #fff;
}
.stepper-box .MuiStepConnector-alternativeLabel {
    top: 14px;
    left: calc(-50% + 15.6px);
    right: calc(50% + 15.6px);
    position: absolute;
    z-index: 1;
}
.stepper-box .MuiStepConnector-lineHorizontal {
    border-top-style: solid;
    border-top-width: 6px;
    border-color: rgb(250 112 51 / 32%);
}
.stepper-box .MuiStep-horizontal {
    padding: 0px;
}
.stepper-box .row.border-bottom-stepper {
    border-bottom: 1px solid #eaeaec;
    margin-bottom: 10px;
}
.stepper-box .MuiStepConnector-lineHorizontal {
    z-index: 2;
}
.stepper-box .MuiStepper-root {
    padding: 5px 5px 10px;
}
.stepper-box .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}
.stepper-box .MuiStepLabel-label {
    color: #a5a5a7;
}
/*-- content --*/
.marginall-auto {
    margin: auto;
}
.stepper-content form .inputwidth .form-group {
    width: 100%;
}
.stepper-content form .form-group input.form-control, .stepper-content form .form-group select{
    height: calc(1.5em + .65rem + 2px);
    padding: 5px;
    border-radius: 2px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
}
.stepper-content form .form-group select option {
    background-color: rgb(232, 240, 254) !important;
}
.stepper-content form .form-group textarea {
    padding: 5px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
}
.height52 {
    height: 52px!important;
}
.stepper-content form .form-group .form-control:focus{
    box-shadow: none;
    border: 1px solid #2992b9;
}
.stepper-content form .form-group label, .stepper-content label {
    /* width: 19%; */
    display: inline-block;
    margin-bottom: .5px;
    font-size: 13px;
    font-weight: 500;
}
.row-flex {
    display: flex;
    margin: 26px 20px 0;
}
.row-flex label {
    position: relative;
    left: -14px;
}
.row-flex p, .row-flex2 span {
    font-size: 13px;
    color: #4c4c4c;
}
.row-flex2 span {
    font-weight: 400;
    padding-left: 5px;
    position: relative;
    top: -1.5px;
}
.row-flex .form-group label {
    color: #4c4c4c;
    font-weight: 400!important;
}
.row-flex2 {
    display: flex;
    margin-bottom: 10px;
}
.row-flex2 .col-md-3, .row-flex .col-md-3 {
    padding: 0px;
}
.stepper-content .row-flex .form-check-input {
        margin-top: .1rem;
        left: 0px;
    top: 2px;
}
.margin-r15 {
    margin-right: 10px;
}
.property-img-admin {
    display: flex;
    margin: 8px 0px;
}
.property-img-admin .star-position {
    position: absolute;
    margin-top: 10px;
    margin-left: 5px;
}
.property-img-admin .display-size {
    height: 80px;
    margin-bottom: 15px;
}
.property-img-admin .display-size img {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dddddd;
}

.or-span-position {
    position: absolute;
    left: 100px;
    color: #4c4c4c;
}
.email-position {
    font-size: 13px;
    color: #565656;
    display: block;
    position: relative;
    left: -110px;
}
.error-top-position {
    top: 122px;
}
.error-top-position2 {
    top: 212px;
}
.error-top-position3 {
    top: 50px;
}
.error-bottom-position {
    top: 49px;
}
.error-bottom-position-4 {
    top: 50px;
    width: max-content;
    left: 16px;
    display: block;
    margin: 0px;
}
.error-bottom-position-6 {
    top: 475px;
    width: max-content;
    left: 121px;
    display: block;
    margin: 0px;
}
/*-- side lable --*/
span.extra-lable {
    background-color: #979797;
    padding: 7px 10px;
    font-size: 15px;
    height: 34px;
    margin-top: 20.5px;
    margin-bottom: 16px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: #ffffff;
}
.input-field .form-group input.form-control {
    border-right: none!important;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}
.display-acres-lot {
    display: flex;
    position: absolute;
    top: 25px;
    right: 68px;
}
.display-acres-lot .form-group, .display-acres-lot2 .form-group {
    margin: 0px;
}
.display-acres-lot span, .display-acres-lot2 span  {
    position: relative;
    top: 2px;
}
.display-acres-lot2 {
    display: flex;
    position: absolute;
    top: 25px;
    right: 17px;
 }
input.input-size {
    width: 160px;
}
@media only screen and (max-width: 1250px){
    input.input-size{
        width: 110px;
    }
}
.room-of-expansion {
    margin-left: 5px;
    position: relative;
    top: -2px;
}
.acres1 {
    position: relative;
    top: 2px;
    cursor: pointer;
}
.auction-position {
    display: flex;
    position: absolute;
    top: 29px;
    left: 75px;
    cursor: pointer;
}
.auction-date {
    /* width: 172px; */
    position: relative;
    /* right: -125px; */
    top: -9px;
    height: fit-content;
    z-index: 1;
    cursor: pointer;
}
.auction-date .react-datepicker-wrapper {
    width: 100%;
}
.auction-date .errorMessage  {
    position: relative;
    top: -2px;
}
.auction-date input {
    height: calc(1.5em + .65rem + 2px);
    padding: 5px;
    border-radius: 2px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
    width: 100%;
}
.description-stepper .form-group textarea {
    height: 135px;
}
.tag-broker-table table {
    margin-bottom: 20px;
    margin-top: 15px;
}
.tag-broker-table thead tr th:nth-child(2) {
    padding-left: 7px!important;
}
.tag-broker-table thead tr th:nth-child(3) {
    padding-left: 1px!important;
}
.tag-broker-table2 table {
    margin-bottom: 20px;
    margin-top: 15px;
}
.tag-broker-table2 thead tr th:nth-child(2) {
    padding-left: 13px!important;
}
.tag-broker-table2 thead tr th:nth-child(3) {
    padding-left: 12px!important;
}
.property-save-add {
    text-align: center;
    margin: 26px 0px;
    font-size: 15px;
    background-color: rgb(220 247 179 / 28%);
    padding: 12px;
    border-radius: 3px;
    color: #4c4c4c;
    font-weight: 500;
    border: 1px solid #94c44b;
}
p.font-italic a {
    font-style: italic;
    padding-left: 4px;
}
.error-tag-select-broker {
    top: 121px;
    left: 16px;
}
.sale-position .form-group, .auction-position .form-group {
    margin: 0px;
}
.sale-position {
    display: flex;
    position: absolute;
    top: 29px;
}
.price-error {
    top: 50px;
    /* left: -50px; */
    width: max-content;
}
.sales-error {
    top: 20px;
    left: -80px;
    width: max-content;
}
.review-listing {
    margin-top: 15px;
}
.review-listing a {
    font-style: italic;
    padding-left: 10px;
}
.new-c-card {
    border-top: 1px solid #ddd;
    margin-bottom: -12px;
    margin-top: 12px;
}
.new-c-card input {
    position: relative;
    left: -38px;
}
.new-c-card .form-group {
    margin-bottom: 8px;
}
.new-c-card label {
    position: relative;
    left: 17px;
    top: -2px;
}
.new-c-card2 {
    width: 100%;
    margin: auto!important;
    background: #e8e7e778;
    padding-top: 11px;
    padding-bottom: 28px;
    border-radius: 3px;
    /* border: 1px solid #ddd; */
    height: 30px;
}
.new-c-card2 .set-btm-mr {
    position: relative;
    left: 184px;
    top: -50px;
    width: 100%;
    margin: auto;
}
.new-c-card2 .col-sm-12 .set-btm-mr .col-sm-2 .form-group input {
    height: calc(1.32em + .65rem + 2px)!important;
}
.new-c-card2 .set-btm-mr .col-sm-2 .form-group .price-error2 {
    font-size: 10px!important;
    position: relative;
    top: -5px!important;
}
.new-c-card2 .set-btm-mr .col-sm-2 .form-group input::placeholder {
    font-size: 14px;
}
.new-c-card2 .col-sm-12 label span svg {
    margin-left: 2px;
    margin-right: 3px;
    width: 14px!important;
}
.new-c-card2 .set-btm-mr .col-sm-2 .price-error2{
    font-size: 10px!important;
    top: -5px!important;
    left: 8px!important;
    width: 200px!important;
    display: block;
}
.card-frame form .new-c-card2:last-child {
    margin-bottom: 15px!important;
}
.new-c-card2 input {
    position: relative;
    left: 7px;
}
.new-c-card2 span {
    position: relative;
    top: -22px;
    left: 6px;
    font-size: 14px;
}
.new-c-card2 p {
    padding-left: 15px;
    margin-bottom: 2px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #7e7e86;
}
.new-c-card2 .form-group {
    margin-bottom: 0px;
}
.new-c-card2 label {
    position: relative;
    left: 17px;
    top: -2px;
    color: #7e7e86;
    height: 21px;
    cursor: pointer!important;
}
.card-frame {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px 15px;
    margin-bottom: 12px;
    margin-top: 11px;
}
.card-frame .row.classrow {
    width: 100%;
    margin: auto;
}
.card-frame .row.classrow .col-sm-6.noterm .form-group {
    margin: 0px;
}
.card-frame .row.classrow .col-sm-6.noterm label {
    position: relative;
    left: 10px;
    top: 23px;
}
.card-frame .row.classrow .col-sm-6.noterm label span {
    position: relative;
    top: 2px;
}
.newcardstepper {
    margin-top: 10px;
}
.newcardstepper .form-group {
    margin: 0px;
}
.newcardstepper .form-group input {
        position: relative;
        left: 7px;
}
.newcardstepper label {
    position: relative;
    left: 17px;
    top: -2px;
}
.newcardstepper label span {
    position: relative;
    top: -20px;
    left: 8px;
}
.savedcardheading {
    padding-left: 5px;
    margin-bottom: 7px;
}
.card-fields {
    margin-bottom: 10px;
}
.card-fields .lable div.sc-bdnylx:first-child {
    width: 100%;
}
.card-fields .jPPSIw {
    display: block;
}
.card-fields #field-wrapper {
    padding: 7.6px;
    border: 1px solid #cacaca;
}
.price-error2 {
    top: 50px;
    width: max-content;
}
.card-fields .sc-bdnylx {
    display: block!important;
    width: 100%!important;
}
.card-fields .price-error2 {
    margin-top: 5px!important;
    display: block!important;
}
.card-fields .kRJgNP, .card-fields p {
    position: relative;
    top: 8px;
    font-size: 11px;
    color: #dc3545;
}
.pricestepper .col-sm-2 {
    flex: 0 0 9.666667%!important;
    max-width: 9.666667%!important;
    padding-right: 0px!important;
}
.pricestepper .col-sm-4 {
    flex: 0 0 40.333333%!important;
    max-width: 40.333333%!important;
    padding-left: 5px!important;
}
/*-- popup --*/
.vendor2-assign-table .modal-content .modal-header h5 {
    color: #262262;
    font-size: 16px;
}
.vendor2-assign-table .modal-body {
    padding: 0px;
    height: 550px;
    overflow-y: scroll;
}
.vendor2-assign-table .modal-content .modal-header {
    padding: 12px 15px;
}
.vendor2-assign-table button.close:focus, .vendor2-assign-table button.close:active, .vendor2-assign-table button.close:hover  {
    border: none;
    outline: 0;
    box-shadow: none;
}
@media (min-width: 576px) {
.vendor2-assign-table.modal-dialog {
    max-width: 1087px;
    margin: 1.75rem 36px 1.75rem auto;
}
}
button.padding-button {
    padding: 5.5px 8px!important;
    margin-right: 0px!important;
    border-radius: 2px!important;
}
button.approved-btn {
    margin-right: 0px;
    background-color: #2992b9;
    padding: 5px 12px 5px 10px;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid #2992b9;
}
button.approved-btn svg {
    margin-right: 5px;
}
button.approved-btn:hover, button.approved-btn:focus, button.approved-btn:active {
    background-color: #2992b9!important;
    border: 1px solid #2992b9!important;
    outline: 0!important;
    box-shadow: none!important;
}
.review-box .list .list-item {
    display: inline-flex;
    table-layout: fixed;
    list-style-type: none;
    list-style-position: outside;
    padding: .21428571em 0;
    line-height: 1.14285714em;
    padding-bottom: 8px;
    color: #58585a;
}
.review-box .list .list-item p.width-length{
    color: #58585a;
    /* margin-right: 10px; */
    width: 100px!important;
    display: block;
}
.review-box .list .list-item p {
    line-height: 20px;
    margin-top: 10px;
    width: 521px;
}
.description-content .tox-tinymce {
    width: 100%!important;
    margin-bottom: 10px;
}
.description-content .tox .tox-statusbar {
    display: none;
}
@media only screen and (min-width: 1270px) and (max-width: 1289px){
    .row-flex {
        display: flex;
        margin: 26px 17px 0!important;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1310px){
    .vendor2-assign-table.modal-dialog {
        max-width: 1029px;
    }
}
@media only screen and (min-width: 1500px) and (max-width: 1550px){
.vendor2-assign-table.modal-dialog {
    max-width: 1087px;
    margin: 1.75rem 124px 1.75rem auto;
}
}