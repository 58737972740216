.total-vendorlist-home {
    font-size: 14px;
    color: #58585a;
    font-weight: 500;
    margin-bottom: 10px;
}
.paid-profilelist .MuiExpansionPanelDetails-root, .free-profilelist .MuiExpansionPanelDetails-root {
    display: block;
    padding: 8px 0px 0px;
}
.paid-profilelist .MuiExpansionPanelSummary-root {
    background-color: #8ec140;
    color: #ffffff;
}
.free-profilelist .MuiExpansionPanelSummary-root {
    background-color: #533a1d!important;
    color: #ffffff!important;
}
.paid-profilelist .MuiIconButton-root,
.free-profilelist .MuiIconButton-root {
    padding: 9px 12px;
    position: absolute;
    left: 0;
}
.paid-profilelist .MuiExpansionPanel-rounded, .free-profilelist .MuiExpansionPanel-rounded {
    margin-bottom: 10px!important;
    outline: 0;
    border: none;
    box-shadow: none;
}
.paid-profilelist .MuiSvgIcon-root, .free-profilelist .MuiSvgIcon-root {
    width: .8em!important;
    height: .8em!important;
    border: 2px solid #fff;
    color: #fff;
}
.paid-profilelist .MuiExpansionPanelSummary-content.Mui-expanded, .paid-profilelist .MuiExpansionPanelSummary-content,
.free-profilelist .MuiExpansionPanelSummary-content.Mui-expanded, .free-profilelist .MuiExpansionPanelSummary-content {
    margin: 7px 26px;
}
.paid-profilelist .MuiExpansionPanelSummary-root.Mui-expanded, .paid-profilelist .MuiExpansionPanelSummary-root,
.free-profilelist .MuiExpansionPanelSummary-root.Mui-expanded, .free-profilelist .MuiExpansionPanelSummary-root {
    min-height: 33px;
    border-radius: 3px;
}
.paid-profilelist .MuiTypography-body1, .free-profilelist .MuiTypography-body1 {
    font-size: 17px;
    font-weight: 500;
}
.paid-profilelist .list-background {
    background-color: #e9e8e8;
    border-radius: 3px;
    /* padding: 15px; */
    margin-bottom: 10px;
    display: flex;
}
.paid-profilelist .vendor-list-img {
    width: 260px;
    height: 140px;
    border-radius: 3px;
    /* margin-right: 10px; */
    object-fit: contain;
    padding: 5px 0px 5px 8px;
}
.paid-profilelist .vendor-list-img img {
object-fit: contain;
/* padding: 5px; */
}
.paid-profilelist h6 {
    font-size: 18px;
    color: #2992b9;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
    font-family: 'Lato', sans-serif!important;
}
.paid-profilelist .vendor-content p {
    font-size: 13px;
    color: #58585a;
    margin-bottom: 15px;
    font-weight: 400;
}
.paid-profilelist .vendor-content p img {
    margin-bottom: 4px;
    width: 10px;
}
.paid-profilelist .vendor-content {
    width:100%;
    padding: 24px 15px 6px 15px;
}
.paid-profilelist .vendor-content a {
    text-decoration: none;
}
.view-more-details {
    float: right;
    font-size: 12px;
    padding: 2px 6px;
    background-color: #1e5699;
    color: #ffffff;
    border-radius: 3px;
    font-weight: 500;
}
a.view-more-details:hover, a.view-more-details:focus, a.view-more-details:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #8ec140;
}
.paid-profilelist .vendor-content p.para-margin-b {
    margin-bottom: 6px!important;
    height: 54px!important;
    overflow: hidden;
}
.free-profilelist .background-white {
    background-color: #ffffff!important;
    /* border: 1px solid #999999!important; */
    border: 1px solid #cccccc!important;
    border-radius: 3px!important;
}
table.vendor-footer td {
    border-bottom:none;
}
table.vendor-footer tfoot {
    float: right;
}
table.vendor-footer {
    margin-top: -15px;
}
table.vendor-footer tr td button.MuiIconButton-root:focus,
table.vendor-footer tr td button.MuiIconButton-root:hover,
table.vendor-footer tr td button.MuiIconButton-root:active {
    outline: 0;
    border: none;
    box-shadow: none;
}
.border-hr {
    border-bottom: 1px solid #b1aeae;
    margin: 20px 0px;
}
.paid-profilelist .list-background a {
    text-decoration: none;
}
.paid-profilelist .list-background:hover {
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.25);
}
.free-profilelist .background-white:hover {
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.25);
}
.paid-profilelist .vendor-content p span {
    background-color: transparent!important;
}
table.vendor-footer .MuiPagination-ul li button:focus,
table.vendor-footer .MuiPagination-ul li button:active,
table.vendor-footer .MuiPagination-ul li button:hover  {
outline: 0px!important;
}
@media only screen and (min-width: 280px) and (max-width: 479px){
    .paid-profilelist .list-background {
        padding: 8px;
    }
    .paid-profilelist .vendor-list-img {
        width: 210px;
        height: 60px;
    }
    .profile-vendordirectory container-fluid {
        padding: 0px 30px 25px!important;
    }
    .gallery-row {
        margin-bottom: 15px;
    }
    .profile-vendordirectory .padding-left-remove {
        padding-left: 15px!important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 996px) {
    .paid-profilelist .vendor-list-img {
        width: 220px;
        height: 80px;
    }
    .paid-profilelist .vendor-content p.para-margin-b {
        margin-bottom: 6px!important;
        height: 54px!important;
        overflow: auto;
    }
}
@media only screen and (min-width: 997px)  and (max-width: 1199px){
    .paid-profilelist .vendor-list-img {
        width: 220px;
        height: 105px;
    }
    .paid-profilelist .vendor-content p.para-margin-b {
        margin-bottom: 6px!important;
        height: 54px!important;
        overflow: auto;
    }
}
@media only screen and (min-width: 480px) and (max-width: 767px){
    .paid-profilelist .vendor-list-img {
        width: 220px;
        height: 80px;
    }
    .paid-profilelist .vendor-content p.para-margin-b {
        margin-bottom: 6px!important;
        height: 54px!important;
        overflow: auto;
    }
}