button.approve-btn {
    background-color: #05ab18;
    border: none;
    font-size: 11px;
    border-radius: 3px;
    color: #fff;
    padding: 3px 7px 2px;
    margin-top: 0px;
    margin-left: 3px;
}
/*-- vendor table --*/
.vendor-directory-table table thead tr th {
    padding: 5px 13px!important;
}
.vendor-directory-table table tbody tr td {
    padding: 6px 13px!important;
}
.vendor-directory-table table tbody tr td:first-child {
    width: 150px;
    word-break: break-word;
}
.addpadding-property {
    padding: 28px 15px!important;
}
@media only screen and (max-width: 1125px){
    .vendor-directory-table table tbody tr td:last-child{
        width: 170px;
    }
}