.addpadding-property {
    padding: 28px 15px!important;
}
.property-table table thead tr th.property-width {
    width: 140px;
}
.width-135 {
    width: 135px!important;
}
.property-table table thead tr th.property-width-city,
.property-list-table .property-table table tbody tr td.property-width-city {
    width: 75px!important;
}
.property-list-table .property-table table tbody tr td,
.property-list-table .property-table table thead tr th {
    padding: 6px 5px!important;
}
.brokername-break {
    width: 102px;
    word-break: break-all;
}
.icon-table{
    height: 24px;
    width: auto !important;
}
.showarch{
    margin-top: 30px;
}
.showtext{
    padding-left: 10px;
}
.pl{
    padding-left: 5px;
}
.bremail div{
    padding-top: 0px !important;
    background: none !important;
    border-radius: 0.25rem !important;
}
.bremail .MuiInputBase-root{
    padding-bottom: 0px;
    height: calc(1.5em + 0.52rem + 3px);
    font-size: 14px;
}
.bremail .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
    padding: 7.5px 4px;
}
ul.MuiAutocomplete-listbox#tags-standard-popup .MuiAutocomplete-option{
    font-size: 14px!important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    overflow: hidden;
}