.vendor-profile-header .row .col-sm-12 h6 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    margin-left: 15px;
    color: #fa7033;
    font-size: 16px;
}
.vendor-profile-header .col-sm-12 .counter-box {
    border-left: 1px solid #ddd;
    padding: 0px 10px;
}
.vendor-profile-header .counter-box h5 {
    font-size: 14px;
    color: #495057;
}
.vendor-profile-header .counter-box h4 {
    font-size: 16px;
    color: #848484;
    margin-top: 20px;
    font-weight: 500;
}
.vendor-profile-header .counter-box label {
    margin-bottom: 0px;
    color: #449dbf;
}
.vendor-profile-header .counter-box p, .vendor-profile-header .counter-box p a {
    margin-bottom: 3px;
    color: #fa7033;
    font-size: 12px;
    margin-top: 3px;
}
.vendor-profile-header .counter-box p a:hover, .vendor-profile-header .counter-box p a:focus, .vendor-profile-header .counter-box p a:active {
    text-decoration: underline;
}
/*-- vendor form --*/
.add-new-company h5 {
    color: #fa7033;
    font-size: 18px;
    margin: 15px 0px;
}
/*-- buttons --*/
button.save-btn {
    background-color: #fa7033;
    border: 1px solid #fa7033;
    padding: 6px 14px;
    border-radius: 3px;
    color: #ffffff;
    font-size: 14px;
    margin-right: 10px;
    font-weight: 500;
}
button.save-btn:hover, button.save-btn:focus, button.save-btn:active {
    background-color: #ff6724!important;
    border: 1px solid #ff6724;
    outline: 0;
    box-shadow: none!important;
    cursor: pointer;
}
button.cancle-btn {
    background-color: #2992b9;
    border: 1px solid #2292b9;
    padding: 6px 14px;
    border-radius: 3px;
    color: #ffffff;
    font-size: 14px;
    margin-right: 10px;
    font-weight: 500;
    cursor: pointer;
}
button.cancle-btn:active, button.cancle-btn:hover, button.cancle-btn:focus {
    background-color: #25a0ce!important;
    border: 1px solid #25a0ce!important;
    outline: 0;
    box-shadow: none!important;
    cursor: pointer;
}
.add-new-company button.reset-btn {
    margin-right: 10px;
}
.add-new-company .text-align-right {
    margin-top: 15px;
    margin-bottom: 15px;
}
.add-new-company .form-group .invalid-feedback {
    position: absolute;
    margin-top: 0px;
}
.add-new-company form .form-group textarea.form-control.desceription-text {
    height: calc(8em + .52rem + 2px)!important;
}
.add-new-company form .vendor-logo {
    width: 350px;
    height: 71px;
    object-fit: contain;
}
.add-new-company form .vendor-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.add-new-company form .logo-vendor-size input {
    margin-bottom: 22px;
}
.add-new-company form .logo-vendor-size .type-container {
    margin-bottom: 15px;
    margin-top: 5px;
}
.add-new-company form .multiple-background-vendors {
    margin-bottom: 22px;
}
.add-new-company form .multiple-background-vendors .display-size {
    margin: 10px auto
}
.add-new-company form .multiple-background-vendors .display-size img {
    border-radius: 3px;
    border: 1px solid #ddd;
}
.WarningBox{
    -webkit-box-shadow : 0px 0px 15px 5px rgba(240, 11, 11, 0.75);
    -moz-box-shadow : 0px 0px 15px 5px rgba(151, 23, 23, 0.75);
     box-shadow: 0px 0px 15px 5px rgba(150, 50, 50, 0.75);
    }
/*-- error msg --*/
.vendor-profile-error {
    /* width: 100%; */
    margin-top: .25rem;
    font-size: 11px;
    display: inline-block;
    color: #dc3545;
}
.vendor-profile-categories {
    margin-top: .25rem;
    font-size: 11px;
    color: #dc3545;
    position: relative;
    top: -9px;
}
.height-67 {
    height: 67px;
}
.error-block {
    display: block;
    top: 45px;
}
.margin-t-7 {
    margin-top: -0.95rem;
}
a.freepaid-link {
    color: #fa7033;
    /* margin-top: 13px; */
    font-weight: 500;
    position: relative;
    font-size: 15px;
    top: 3px;
}
a.freepaid-link:hover {
    color: #fa7033;
    text-decoration: underline;
}
.vendorname-categories {
    height: auto;
    max-height: calc(4em + .2rem + 1px);
    padding: 6px 8px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
    border-radius: 4px;
    overflow-y: auto;
}
.categoriesbtn {
    height: calc(1.5em + .59rem + 2px);
    /* padding: 6px 3px; */
    display: block;
    font-size: 12px;
    background-color: #979797;
    border: 1px solid #979797;
    border-radius: 3px;
    text-align: center;
    margin-right: 3px;
}
.categoriesbtn a {
    color: #ffffff;
    display: block;
    padding: 6px 0px;
}
.categoriesbtn a:hover {
    color: #ffffff;
    text-decoration: none;
}
.categoriesbtn svg {
    font-size: 11px;
}
.display-flex {
    display:flex;
}
.row.displaycategories {
    margin: auto;
    width: 100%;
}
.description-vendor .tox-tinymce {
    width: 100%!important;
    margin-bottom: 10px;
}
.description-vendor .tox .tox-statusbar {
    display: none;
}
.v-marg-error {
    margin-bottom: 19px;
    margin-top: 15px;
}

.image-cropper.modal-dialog{
    max-width: 450px;
}
.image-cropper .modal-header{
    padding: 3px 1rem;
}
.image-cropper .modal-body{
    max-height: 500px;
    overflow-y: auto;
}
.image-cropper .modal-body::-webkit-scrollbar {
    width: 12px;
} 
.image-cropper .modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
} 
.image-cropper .modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
/* --- media query ---*/
@media only screen and (min-width: 280px) and (max-width: 767px) {
.vendor-profile-header .col-sm-12 .counter-box {
    width: 100%;
    margin-top: 10px;
}
}