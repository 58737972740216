.box-shadow-account {
    background: #fff;
    padding-top: 15px;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
    padding-bottom: 15px;
    border-radius: 5px;
}
.user-account .row {
    margin: auto;
}
h5.account-head {
    padding: 0 10px 10px 11px;
    font-size: 16px;
    color: #2992b9;
    margin-bottom: 0;
    margin-left: 4px;
}
.account-sapce {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
 }
 .my-account-label {
    font-weight: 500;
    margin-bottom: 2px;
    font-size: 12px;
}
.result-data {
    font-size: 12px;
    font-weight: 400;
    border: 0;
    background-color: #fff;
    margin-bottom: 10px;
    opacity: .6;
}
.brd-right {
    border-right: 1px solid #dee2e6!important;
}
.right-button-account {
    text-align: right;
    line-height: normal;
}
button.edit-account {
    background-color: #fff!important;
    color: #f16401;
    font-size: 12px;
    padding: 0px;
    border: 0px!important;
    outline: none!important;
    box-shadow: none!important;
    text-decoration: underline;
}
button.edit-account:focus, button.edit-account:active, button.edit-account:hover {
    background-color: #fff!important;
    color: #f16401!important;
    border: 0px!important;
    outline: none!important;
    box-shadow: none!important;
}
.total-watchlist {
    width: 100%;
    margin-left: auto!important;
    margin-right: auto!important;
    margin-top: 23px;
}
.total-watchlist p{
    margin-bottom: 0px;
}
.total-watchlist img {
    width: 16px;
    margin-left: 5px;
}