.loader-xcel {
  background-image: url(../../../Assets/Img/loader-download.gif);
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 18px auto;
  display: block;
  text-align: center;
  /* margin: auto; */
  margin-left: 27px;
  cursor: pointer;
}

.continue-botton-modal{
    margin: 16px 8px 14px;
    width: 152px !important;
    text-transform: none;
  }
  .continue-botton-modal:hover{
      background: #171717 !important;
    }
  
  .landing-stepper .stepper-align {
    display: flex;
    padding: 8px 10px 0px;
  }
  
  .landing-stepper .MuiFormControlLabel-root-131 {
    margin-right: 0px;
  }
  .landing-stepper .MuiStepIcon-root-103.MuiStepIcon-completed-106 {
    color: #fa7033;
  }
  .st-color {
    color: #fa7033;
    font-weight: 600;
  }
  .st-black{
    color: #000;
    font-weight: 600;
  }
  
  .landing-stepper .MuiStepIcon-root-103.MuiStepIcon-active-105 {
    color: #fa7033;
  }
  
  .landing-stepper .MuiRadio-colorSecondary-134.MuiRadio-checked-131 {
    color: #fa7033;
  }
  
  .stepper-align svg{
    color: #fa7033!important;
  }
  
  .landing-stepper .bulleting{
    display: list-item;
    margin-left: 15px;
    color: #5A6E7F;
    font-family: Roboto;
    font-size: 13px;
    line-height: 18px;
  }
  
  .landing-stepper #field-wrapper{
    border: 1px solid #ced4da;
    padding: 7px 2px;
    border-radius: 2px;
  }
  
  .landing-stepper .ixTfPP {
    display: block;
  }
  
  .landing-stepper .hlTfmo {
    margin-left: 0.5em;
    margin-top: 0.2em;
  }
  
  .landing-stepper .terms_cond{
    position: relative;
    margin-left: 4px;
  }
  .tc_dialog p{
    font-size: 14px;
  }
  body .landing-stepper .terms_cond .MuiCheckbox-root{
    margin-top: -4px;
    margin-left: 3px;
    padding: 5px;
  }
  body .landing-stepper .terms_cond .MuiCheckbox-root svg{
    font-size: 18px;
  }
  .terms_cond .form-check{
    float: left;
    display: block;
    margin-right: 5px;
    position: relative;
  }
  .terms_cond a{
    color: #fa7033;
    text-decoration: underline;
  }
  .terms_cond a:hover, .terms_cond a:focus, .terms_cond a:active {
    color: #ff6724;
  }
  .landing-stepper .terms_cond label{
    margin-bottom: 0px;
    display: table;
    color: #000;
    opacity: 1;
    font-size: 12px;
  }
  .MuiStepLabel-root .MuiStepLabel-alternativeLabel svg.MuiSvgIcon-root {
    width: 33px;
    height: 33px;
    background: #fa7033;
    border-radius: 50%;
    margin: 0px;
  }
  .landing-stepper .terms_cond .btn-link {
    font-weight: 400;
    color: #fa7033;
    text-decoration: underline;
    padding-left: 0;
    float: left;
    font-size: 13px;
    padding: 0;  
    text-transform:none;
    margin-bottom: 0;
  }
  
  .landing-stepper .react-tel-input input[type=tel]{
    width:100%;
  }
  
  .landing-stepper .phoneValidate  {
    border: 1px solid #fa7033 ;
  }
  
  .landing-stepper .kJnpoy.is-invalid {
    border: 1px solid #fa7033;
  }
  
  .landing-stepper .closeModal,
  .tc_dialog .closeModal{
    position: absolute;
    top: 10px;
    right: 10px;
    outline: none !important;
  }
  .landing-stepper .Mui-disabled .MuiStepLabel-iconContainer svg.MuiSvgIcon-root {
    border: 2px solid #fa7033;
  }
  .landing-stepper .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
    margin-top: 5px;
    font-weight: 500;
    font-family: Roboto;
    color: #000;
  }
  .landing-stepper .Mui-disabled .MuiStepLabel-label {
    opacity: 0.5;
  }
  .landing-stepper h5.st-sub-change{
    font-size: 14px!important;
    font-weight: 500;
    color: #565656;
  }
  .hom-text-bd {
    font-size: 14px!important;
    font-weight: 500;
  }
  .hom-text-bd.font-sets{
    font-size: 13px !important;
  }
  .promoCode_btn .btn{
    padding: 0px 60px;
    text-transform: none;
    background: #313232 !important;
    border-color: #313232 !important;
    height: 33px;
    line-height: 28px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    margin-bottom: 30px;
  }
  .enter-acc-details.mar-min-top {
    margin-top:-5px
  }
  .MuiTypography-gutterBottom.fnt-14 {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Lato', sans-serif!important;
  }
  
  .closeModal:hover{
    background: none !important;
  }
  
  .bs .optHead{
    background-color: #f6f6f6;
  }
  
  .multiple-Subcription .multiple-close{
    margin: 0 auto;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .discount-strike{
    text-decoration: none;
    background-image: linear-gradient(transparent 7px,#f15d47 7px,#f15d47 10px,transparent 9px);
    font-weight: 400;
    padding: 0px 3px;
  }
  
  .enter-acc-details .ciTvcc {
    display: none;
  }
  
  .h-pop-head {
    height: 48px;
    color: #565656;
    /* font-family: Roboto; */
    font-size: 20px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
  }

  .landing-stepper .radio_cls .MuiTypography-body1{
      font-size: 12px;
  }
.move-one-btm{
  position: relative;
  top: 12px;
}
  .landing-stepper .ld_fs label{
    margin-bottom : 0!important;
  }
  body .mvp-landing-popup .MuiDialog-paperWidthSm{
    max-width: 608px;
    width: 608px;
  }
  body .mvp-landing-popup.mvp-add-market .MuiDialog-paperWidthSm{
    max-width: 750px;
    width: 750px;
  }
  body .mvp-landing-popup.mvp-add-market .MuiDialog-paperWidthSm svg text.MuiStepIcon-text{
    font-size: 12px!important;
    font-weight: 500;
  }
  .landing-popup.mvp-landing-popup .form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: none!important;
}
.landing-popup.mvp-landing-popup .loading{
  left: calc(50% - 0px);
}
.create-acc-promo.bill-promo .apply-code{
  margin-bottom: 3px;
}
.create-acc-promo.bill-promo{
  margin-bottom: 5px;
}
.apply-code .promoCode {
  display: inline-block;
  width: 50%;
  border-radius: 2px;
  height: 30px;
  line-height: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.apply-code .apply-btn {
  float: right;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  background: #fa7033;
  border: none;
  height: 30px;
  padding: 0 20px;
  text-transform: none;
  font-size: 14px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  font-weight: 500;
  line-height: 18px;
}
.apply-code .apply-btn:focus {
  box-shadow: none!important;
  outline: 0!important;
}
.apply-code {
  margin-top: 5px;
  margin-bottom: 25px;
  text-align: right;
}
.bill-promo h6 {
  font-size: 14px;
}
.bill-promo {
  background: #f6f6f6;
}
.bill-promo .apply-promo {
  background: #f6f6f6;
  margin-top: 5px;
  box-shadow: 0 0 0;
}
.enter-acc-details h3, .enter-acc-details h5 {
  margin-bottom: 0;
  color: #565656;
  font-size: 16px;
  font-weight: 600;
}
.enter-acc-details label {
  opacity: .9;
  color: #565656;
  font-size: 12px;
  margin-bottom: 1px;
}
.enter-acc-details .form-control {
  border-radius: 2px;
  padding: 0 8px;
  height: 30px;
  line-height: 30px;
  opacity: .8;
  color: #000;
  font-family: Roboto;
  font-size: 12px;
}
.landing-stepper .MuiDialogContent-root {
  padding: 8px 10px!important;
}
.font-sz-13 {
  font-size: 13px;
}
.enter-acc-details .form-group {
  margin-bottom: 0;
  margin-top: 1px;
}
.landing-stepper .step_btn .next-btn {
  text-transform: none;
  padding: 0 25px;
  margin-bottom: 0;
  margin-right: 0;
  line-height: 28px;
  background: #2992b9;
  border: 1px solid #2992b9;
  height: 32px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
}
.landing-stepper .step_btn .next-btn:hover, .landing-stepper .step_btn .next-btn:focus, .landing-stepper .step_btn .next-btn:active {
  background: #25a0ce!important;
  border: 1px solid #25a0ce!important;
  outline: 0!important;
  box-shadow: none!important;
}
.landing-stepper .step_btn .go-back-btn {
  background: none!important;
  border: none!important;
  text-transform: none;
  padding: 0;
  outline: none!important;
  box-shadow: none!important;
  margin-bottom: 0;
  opacity: .7;
  color: #565656!important;
  font-family: Roboto;
  font-size: 14px;
}
.landing-stepper .errorMsg, .landing-stepper .invalid-feedback {
  font-size: 11px;
}
.landing-stepper .step_btn {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  text-align: right;
}
.landing-popup .MuiStepConnector-lineHorizontal {
  border-top-width: 6px;
  margin-top: 2px;
}
.landing-popup .MuiStepConnector-alternativeLabel {
  top: 12px;
  left: calc(-50% + 16px);
  right: calc(50% + 16px);
  position: absolute;
}
.landing-popup .MuiStepConnector-line {
  display: block;
  border-color: rgb(250 112 51 / 25%);
}
.enter-acc-details .MuiCheckbox-colorSecondary.Mui-checked {
  color: #fa7033!important;
}
.text-red{
  color: #dc3545;
}
h3.thank-you{
  color: #000000;
  /* font-family: Roboto; */
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
}
.last-step .elevation0{
  box-shadow: none;
}
.payment-table.custom-table.table-bordered thead th{
  font-size: 10px;
  padding: 2px 10px 1px 10px;
}
.payment-table.custom-table.table-bordered thead th:first-child{
  width: 100px;
}
.payment-table.custom-table.table-bordered thead th:nth-child(2){
  width: 150px;
}
.payment-table.custom-table.table-bordered thead th:nth-child(3){
  width:85px;
}
.payment-table.custom-table.table-bordered thead th:nth-child(4){
  width: 100px;
}
.payment-table.custom-table.table-bordered thead th:last-child{
  width: 115px;
}
.last-step .loading{
  left: calc(50% - 0px);
}
.disabled-link {
  pointer-events: none;
}
.last-step h6 {
  color: #5a6e7f;
  margin-bottom: 10px;
  font-size: 16px;
}
.loader-xcel {
  background-image: url(../../../Assets/Img/loader.gif);
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 60px auto;
  display: block;
  text-align: center;
  /* margin: auto; */
  margin-left: 27px;
  cursor: pointer;
}
.btn-secondary.go-to-mvp{
  background-color: #2992b9;
  text-transform: none;
  margin-top: 0px;
  padding: 5px 30px;
  font-size: 14px;
  border: 1px solid #2992b9;
  font-weight: 500;
  margin-bottom: 10px;
}
.btn-secondary.go-to-mvp:focus {
  box-shadow: none!important;
  outline: none!important;
  background-color: #25a0ce;
  border: 1px solid #25a0ce;
}
.btn-secondary.go-to-mvp:hover {
  background-color: #25a0ce;
  box-shadow: none!important;
  outline: none!important;
  border: 1px solid #25a0ce!important;

}
.btn-secondary.go-to-mvp:active {
  background-color: #25a0ce;
  box-shadow: none!important;
  outline: none!important;
  border: 1px solid #25a0ce!important;
}
.landing-popup .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.forgot-password-mvp {
  margin-bottom: 15px;
    display: block;
    margin-top: 15px;
    font-size: 16px;
    color: #fa7033;
    text-decoration: underline;
}
.radius-select .MuiSlider-root {
  color: #979797;
}
.radius-select .MuiSlider-thumb .MuiSlider-valueLabel {
  left: calc(-48% - 0px)!important;
  display: none!important;
}
.radius-select .MuiSlider-thumb .PrivateValueLabel-offset-3, .radius-select .jss3, .radius-select .MuiSlider-valueLabel {
  top: -17px!important;
}
.radius-select .PrivateValueLabel-circle-4, .radius-select .jss4 {
  width: 21px!important;
  height: 21px!important;
  display: none!important;
}
.radius-select h5 span {
  font-size: 13px;
}
.radius-select .MuiSlider-markLabel {
    top: 24px;
    font-size: 0.84rem;
    width: 20px;
    text-align: center;
    z-index: 1;
}
.padd-19 {
  padding-top: 12px;
}
.margin-t7 {
  margin-top: 7px;
}
.radius-select h5 {
  margin-top: 7px!important;
}
.font-sty{
  color: #565656;
    font-size: 16px;
    font-weight: 600;
}
.padd-l-15 {
  padding-left: 15px!important;
 }
 /*saved card details*/
.scheading {
  padding-left: 15px;
  margin-bottom: -3px;
  margin-top: 5px;
  opacity: .9;
  color: #565656;
  font-size: 12px;
}
.new-c-card-sc {
  width: 100%;
  margin: auto!important;
  background: #ffffff;
  padding-top: 8px;
  padding-bottom: 26px;
  border-radius: 3px;
  /* border: 1px solid #ddd; */
  height: 28px;
}
.new-c-card-sc label {
  position: relative;
  left: 17px;
  top: -2px;
  color: #7e7e86;
  height: 21px;
  cursor: pointer!important;
}
.newcardstepper-sc.col-sm-12 {
  margin-top: 19px;
  margin-bottom: 5px;
  border: 0px;
  left: -20px;
  top: -11px;
  height: 7px;
  padding: 0px 0px 0px 15px;
}
.newcardstepper-sc label {
  position: relative;
    left: 31px;
    top: -2px;
}
.newcardstepper-sc .form-group {
  margin: 0px;
}
.newcardstepper-sc label span {
  position: relative;
  top: -20px;
  left: 8px;
}
.newcardstepper-sc .form-group input {
  position: relative;
  left: 9px;
  width: 11px;
}
.new-c-card-sc input {
  position: relative;
    left: 3px;
    width: 11px;
}
.new-c-card-sc span {
  position: relative;
  top: -18px;
  left: 6px;
  font-size: 12px;
}
.new-c-card-scp {
  border: 0px;
  width: 100%;
  margin: 0px 0px!important;
}
.new-c-card-sc .form-group {
  margin-bottom: 0px;
}
.new-c-card-sc .col-sm-12 label span svg {
  margin-left: 2px;
  margin-right: 3px;
  width: 14px!important;
}
.new-c-card-sc .col-sm-12 {
  display: flex;
}
.cvv-input-sc input {
  width: 100%;
  height: 27px!important;
}
.cvv-input-sc .form-group {
  width: 53px;
  /* height: 22px; */
  position: relative;
  left: 28px;
  top: -4px;
}
.sc-error {
  position: absolute!important;
    top: 21px!important;
    font-size: 11px!important;
    left: 16px !important;
    color: #dc3545!important;
}