.property-details {
    min-height: calc(100vh - 400px);
    background-color: #ffffff;
    position: relative;
    top: 2px;
}
.table-property-tag table tbody tr td {
    border: 0px;
    padding: 15px 11px;
    vertical-align: middle;
}
.table-property-tag table tbody tr td.second-td-p {
    padding: 9px 11px!important;
}
.second-td-p h3 {
    color: #555759;
    font-size: 18px;
    text-transform: capitalize!important;
    margin-bottom: 0px;
    font-weight: 600;
    line-height: 1.28571429em;
    text-transform: none;
    font-family: 'Lato', sans-serif!important;
}
.second-td-p span {
    font-size: 14.3px;
    white-space: nowrap;
    color: rgba(0,0,0,.87);
    margin: 0px 15px 0px 0px;
}
.table-property-tag table {
    margin-bottom: 0px;
}
.table-property-tag {
    border-bottom: 1px solid rgb(225 225 225);
    padding: 0px 93px;
    position: sticky;
    top: 0px;
    z-index: 11;
    background-color: #fff;
}
.property-toptag {
    background-color: #565656;
    border-color: #565656;
    margin: 0px 10px 0px 0px;
    width: 102px;
    text-align: center;
    color: #fff!important;
    font-size: 12px;
    display: inline-block;
    line-height: 1;
    border-radius: 3px;
    padding: 7px 9px;
    vertical-align: initial;
    font-weight: 700;
}
.calculator-dropdown h3 {
    margin-bottom: 0px;
    display: inline-flex;
    margin-top: 5px;
}
.calculator-dropdown .calculator-icon {
    font-size: 1.32rem;
    color: #2992b9!important;
    cursor: pointer;
    transition: opacity .1s ease;
}
.dropdowncalculator {
    margin-right: 4px;
    color: #2992b9!important;
    cursor: pointer;
}
button.calculatorbutton {
    background-color: transparent;
    border: none;
    padding: 0px;
    outline: none;
    box-shadow: none;
}
button.calculatorbutton:hover, button.calculatorbutton:focus, button.calculatorbutton:active {
    background-color: transparent!important;
    border: none!important;
    padding: 0px;
    outline: none!important;
    box-shadow: none!important;
}
.width-236 {
    width: 20.5%;
}
.table-property-tag table tbody tr td.calculator-dropdown {
    /* width: 16%!important; */
    padding-right: 3px!important;
    text-align: right;
}
.calculator-price {
    color: #555759;
    vertical-align: middle;
    padding-left: .55rem;
    font-size: 18px;
    font-weight: 700;
    margin-top: 1px;
    font-family: 'Lato', sans-serif!important;
}
.calculator-dropdown .loancalculator {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 62px;
    background-color: rgb(244, 245, 249);
    border-bottom: 1px solid rgba(34,36,38,.1);
    padding: 0px;
    z-index: 1;
}
.calculator-dropdown .loancalculator h3, .calculator-dropdown .loancalculator .leftColor-separator {
    display: none;
}
.calculator-dropdown .loancalculator .container {
    padding: 0px;
}
.calculator-dropdown .loancalculator .row {
    width: 100%;
    margin: auto;
}
.calculator-dropdown .calculator-box1 .calculator-fields1 input, .calculator-dropdown .calculator-box1 .calculator-fields2 input {
    font-size: 14px;
    padding: 9.5px 14px;
}
.calculator-dropdown .calculator-box1 div.cal-button-left, .calculator-dropdown .calculator-box1 div.cal-button-right {
    font-size: 14px;
    padding: 12px;
}
.calculator-dropdown .calculator-form .row.calculator-row {
    padding-bottom: 7px;
}
/*-- container-property --*/
.container-property {
    padding: 25px 80px 25px 106px!important;
}
.container-property .row {
    width: 100%;
    margin: auto;
}
.property-heading {
    color: #555759;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 14px;
    font-family: 'Lato', sans-serif!important;
}
.property-heading span {
    color: #555759;
    font-size: 14px;
    font-weight: 600;
    padding-left: 6px;
    margin-bottom: 14px;
    font-family: 'Lato', sans-serif!important;
}
.table-propertydetails table tbody tr td, .table-propertydetails-condition table tbody tr td {
    font-size: 13px;
    padding: 10px;
    border-right: none;
}
.table-propertydetails table tbody tr td a, .table-propertydetails-condition table tbody tr td a {
    color: #555759;
    /* font-style: italic; */
    text-decoration: underline;
}
.table-propertydetails table tbody tr td a:hover, .table-propertydetails-condition table tbody tr td a:hover {
    color: #fa7033;
}
.table-propertydetails table, .table-propertydetails-condition table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: none;
    border-radius: .28571429rem;
    text-align: left;
    color: rgba(0,0,0,.87);
    border-collapse: initial;
    border-spacing: 0;
}
.table-propertydetails table tbody tr td:last-child, .table-propertydetails-condition table tbody tr td:last-child {
text-align: right;
}
.table-propertydetails table tbody tr td, .table-propertydetails-condition table tbody tr td {
    border-top: none;
    border-left: none;
}
.table-propertydetails table tbody tr:last-child td, .table-propertydetails-condition table tbody tr:last-child td {
    border-bottom: none;
}
.market-insighs {
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: none;
    border-radius: .28571429rem;
}
.powedbystortack {
    position: absolute;
    font-size: 11px;
    margin-bottom: 0px;
    top: 26px;
    right: 16px;
    font-style: italic;
}
.Property-contact-box {
    position: relative;
    background: #f3f4f5;
    color: rgba(0,0,0,.6);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    margin: 45px 0 1rem;
    padding: 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);
}
.Property-contact-box p {
    font-weight: bolder;
    color: #565656;
    font-size: 13px;
}
.Property-contact-box form .form-group {
    width: 100%;
}
.Property-contact-box form .form-group input, .Property-contact-box form .form-group textarea {
    font-family: 'Lato', sans-serif!important;
    margin: 0;
    outline: 0;
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 13px;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: 5px;
    box-shadow: inset 0 0 0 0 transparent;
}
.Property-contact-box form .form-group input:hover, .Property-contact-box form .form-group input:focus, .Property-contact-box form .form-group input:active,
.Property-contact-box form .form-group textarea:hover, .Property-contact-box form .form-group textarea:focus, .Property-contact-box form .form-group textarea:active
{
    color: rgba(0,0,0,.95);
    border-color: #85b7d9;
    border-radius: .28571429rem;
    background: #fff;
    box-shadow: inset 0 0 0 0 rgba(34,36,38,.35);
}
.Property-contact-box form .form-group textarea {
    height: 71px;
}
.Property-contact-box form .form-group .invalid-feedback {
    position: absolute;
    margin-top: 0px;
    font-size: 10px;
}
.p-contant-checkbox .form-check {
    position: absolute;
    left: 0px;
    display: inline-block;
    backface-visibility: hidden;
    outline: 0;
    vertical-align: initial;
    font-style: normal;
    min-height: 17px;
    font-size: 1rem;
    line-height: 17px;
    min-width: 17px;
}
.p-contant-checkbox label {
    cursor: pointer;
    position: relative;
    display: block;
    padding-left: 22px;
    outline: 0;
    color: #565656;
    font-size: 13px;
    margin-bottom: 2px;
}
button.send-contactbutton {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    background-color: #2992b9;
    width: 100%;
    vertical-align: initial;
    color: #ffffff;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-family: 'Lato', sans-serif!important;
    font-weight: 600;
    font-size: 14px;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
}
button.send-contactbutton:hover, button.send-contactbutton:focus, button.send-contactbutton:active {
    background-color: #25a0ce;
}
button.greenbutton {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    background-color: #8ec140;
    width: 100%;
    vertical-align: initial;
    color: #ffffff;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-family: 'Lato', sans-serif!important;
    font-weight: 600;
    font-size: 14px;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
}
button.greenbutton:hover, button.greenbutton:focus, button.greenbutton:active {
    background-color: #98d247;
    color: #fff;
    text-shadow: none;
}
/*-- slider--*/
.col-md-9.property_img {
    padding-left: 0px;
    padding-right: 30px;
    margin-bottom: 40px;
}
.property_img .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #3797dd;
}
.property_img .carousel .control-next.control-arrow:before {
    border-left: 8px solid #3797dd;
}
.property_img .carousel .thumbs {
    display: none;
}
.property_img .selected {
    border: 1px solid #dee2e6;
}
.image-carousel-size ul.control-dots {
    display: none;
}
.listing-img-resize {
    background-color: #fff;
    height: 350px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
}
.review-noimg {
    height: 311px!important;
}
.review-description {
    max-height: 267px!important;
}
p.review-description p span {
    font-size: 14px!important;
}
.listing-img-resize img {
    object-fit: contain;
    height: 100%;
    border-radius: 5px;
}
.property_img .carousel .slide {
    background: #fff!important;
    padding: 0px;
    border-radius: 5px;
}
.property_img .carousel.carousel-slider {
    box-shadow: 0px 0px 4px #ddd;
}
/*-- description --*/
.property-v-description pre {
    padding: 0 0 0 3px;
    margin: 15px 0;
    font-size: 13px;
    height: auto;
    max-height: 304px;
    overflow-y: auto;
    font-family: 'Roboto';
    word-break: break-word;
    white-space: pre-wrap;
    color: #555759;
}
.description-heading {
    padding: 0;
    font-family: 'Lato', sans-serif!important;
    font-weight: 700;
    line-height: 1.28571429em;
    text-transform: none;
    font-size: 24px;
    color: #555759;
}
/*---map-property ---*/
.map-property {
    background: #f3f4f5;
    color: rgba(0,0,0,.6);
}
.row.property-serach-map {
    width: 100%;
    margin: auto;
}
.property-map-size {
    width: 84%;
    /* height: 57%; */
    margin: 0px auto 20px;
}
.map-property a {
     font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
    color: #000000de;
    font-weight: 400;
    font-size: 14px;
    color: #000000de!important;
    margin-right: 20px;
    margin-top: 37px;
    padding-bottom: 5px;
    cursor: pointer;
}
.map-property a.active {
    border-bottom: 2px solid #fa7033;
    font-weight: 400!important;
}
.map-property a:hover {
    border-bottom: 2px solid #cccccc;
    cursor: pointer;
}
/*-- condition --*/
.table-property-tag-condition {
    border-bottom: 1px solid rgb(225 225 225);
    padding: 0px 15px;
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #fff;
}
.table-property-tag-condition table td, table th {
    border: none;
    vertical-align: middle;
}
.container-property-condition .row {
    width: 100%;
    margin: auto;
}
.table-property-tag-condition table {
    margin-bottom: 0px;
}
.table-propertydetails-condition.col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
}
.table-property-tag-condition .calculator-dropdown .loancalculator {
    top: 68px;
    padding: 1px 25px!important;
}
.container-property-condition {
    padding: 25px 25px 25px 25px!important;
}
.width-236-condition {
    width: 23.5%;
}