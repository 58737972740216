.page-loading {
    background-image: url('../../Assets/Img/loader.gif');
    position: fixed;
    /* top: 50%; */
    left: 40px;
    margin-top: -25px;
    margin-left: -25px;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    z-index: 2099;
    display: block;
    background-size: 60px auto;
    border: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
  .loader-wrap {
    pointer-events: auto;
        background: rgba(255, 255, 255, 0.6);
        width: 100%;
        height: 100%;
  }