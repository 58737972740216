.admin-logo {
    padding-top: 8px;
    padding-bottom: 8px;
}
.admin-logo img.logo-adm {
    width: 150px;
    margin: 0 auto;
    display: block;
}
@media screen and (min-width: 600px) {
body #left-nav-bar .MuiDrawer-paperAnchorDockedLeft {
    width: 200px;
    z-index: 0;
}
}
#left-nav-bar main {
    margin-left: 200px;
}
.admin-menu-text.MuiList-padding {
    padding: 0px;
}
.admin-menu-text span {
    font-size: 12px;
    font-weight: 500;
    color: #565656;
}
.admin-menu svg {
    width: 16px;
}
.admin-menu .MuiListItemIcon-root {
    margin-right: 10px;
    min-width: 10px;
}
.admin-menu .MuiListItem-root{
    padding-top: 6px;
    padding-bottom: 6px;
}
.admin-menu .set-padding {
    padding-top: 3px;
    padding-bottom: 3px;
}
a.admin-menu:hover {
    text-decoration: none;
}
.admin-menu .MuiListItem-button:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}
.admin-menu img.icon-admin {
    width: 14px;
    margin-right: 13px;
}
/*-- admin top --*/
.admin-top .row.adminrow {
    background-color: #ffffff;
    box-shadow: 0px 1px 3px 0px #ddd;
    width: 100%;
    margin: 0px;
    padding: 3px 0px;
}
.admin-top .adminrow  .directory-path {
    margin:0px;
    padding: 0!important;
    background-color: #ffffff;
}
.admin-top .row.adminrow .breadcrumb {
    margin-top: 9px;
    margin-bottom: 9px!important;
    background-color: #ffffff;
}
.admin-top .adminrow .breadcrumb li.breadcrumb-item {
    font-size: 13px;
    color: #7b7a7a;
}
.admin-top .adminrow .breadcrumb li.breadcrumb-item a {
    color: #b1b1af;
    font-weight: 400;
    text-decoration: none;
}
.admin-top .adminrow .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    background-image: url('../../Assets/Img/breadcrumb.e7086ec0.png');
    background-repeat: no-repeat;
    font-size: 0;
    width: 10px;
    height: 10px;
    position: relative;
    top: 3px;
}
.admin-top .adminrow .breadcrumb .breadcrumb-item+.breadcrumb-item {
    padding-left: 0px;
}
.admin-top ul {
   list-style: none;
   padding: 0px;
   margin: 0px;
}
.admin-top .col-sm-4 .row {
    margin-top: 3px;
    float: right;
    margin-right: 20px;
}
.admin-top .welcome-header {
    font-size: 1rem;
    font-weight: 400;
    color: #7f7f7f;
    margin-left: 10px;
    height: 30px;
}
.admin-top .welcome-header .top-welcome {
    font-size: 10px;
    opacity: .5;
    color: #000;
    margin-bottom: -7px;
}
.admin-top .welcome-header span {
    font-size: 12px;
    opacity: .7;
    color: #000;
    font-weight: 400;
    line-height: 28px;
}
.admin-top .col-sm-4 .usericon {
    height: 24px;
    width: 24px;
    margin-top: 2px;
}
.market-button .MuiListItem-gutters {
    padding-left: 42px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 5px;
}
.market-button span {
    font-weight: 400;
    color: #4c4c4c;
    font-size: 10px;
    text-decoration: none;
}
.market-button .MuiListItemText-root {
    margin: 3px 0;
}
.market-button-disabled .MuiListItem-gutters {
    padding-left: 42px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 5px;
}
.market-button-disabled span {
    font-weight: 400;
    color: #bbb9b9;
    font-size: 10px;
    text-decoration: none;
}
.market-button-disabled .MuiListItemText-root {
    margin: 3px 0;
}
.admin-menu-text a {
    text-decoration: none!important;
}
.sub-menu-list-wrap{
    height: auto;
    overflow-y: scroll;
    max-height: 190px;
}
/* Scrollbar styles */
.sub-menu-list-wrap::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.sub-menu-list-wrap::-webkit-scrollbar-track {
    border: 1px solid #979797;
    background: #979797;
    padding: 2px 0;
    background-color: #979797;
    border-radius: 0px;
}
.sub-menu-list-wrap::-webkit-scrollbar-thumb {
    border-radius: 0px; 
    box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #fa7033;
    border: 1px solid #fa7033;
}