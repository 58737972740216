@media (min-width: 576px) {
.modal-dialog.popup-login {
    max-width: 380px!important;
    margin: 1.75rem auto;
}
}
.popup-login .modal-header {
    display: none;
}
.popup-login .login-lss {
    padding: 15px 20px ;
}
.popup-login p {
    text-align: center;
    font-size: 14px;
    color: #7c7c7c;
}
.popup-login h4 {
    text-align: center;
    color: #fa7033;
    font-weight: bolder;
    font-size: 1.8em;
    text-transform: uppercase;
    margin-bottom: 14px;
    font-family: "Lato"!important;
}
.popup-login h5 {
    text-align: center;
    color: #565656;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 19px;
}
.text-skip {
    text-align: center;
    margin-bottom: 14px;
}
.text-skip span {
    color: #007bff;
    font-size: 16px;
    font-style: italic;
    font-family: "Lato"!important;
}
.text-skip span:hover {
    color: #002d73;
    text-decoration: underline;
}
.text-skip span svg {
    margin-left: 2px;
    width: 11px!important;
    position: relative;
    top: 1px;
}
.popup-login .form-control {
    font-size: 14px;
    background-color: #f6f6f6;
    padding: 18px;
}
.popup-login .form-control:focus, .basic-details .form-control:focus {
    border-color: #85b7d9;
    box-shadow: none;
    outline: none;
}
.popup-login .form-control.is-invalid:focus, .popup-login .form-control:invalid:focus {
    box-shadow: none!important;
}
.login-close
{
    position: relative;
}

.login-close .login-x-close
{
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 20px;
    cursor: pointer;
    color: #565656;
    font-weight: bold;
    font-family: 'Roboto';
}
.basic-details
.popup-login .form-group .invalid-feedback {
    position: absolute;
    font-size: 10px;
    margin: 0px;
}
.popup-login .signin-blue {
    width: 100%;
    padding: 7px;
    background-color: #2992b9;
    border: none;
}
.popup-login .signin-blue:hover, .popup-login .signin-blue:focus {
    box-shadow: none!important;
    background-color: #25a0ce!important;
    color: #ffffff;
    text-decoration: none;
}
.popup-login .text-right {
    float: right;
}
.popup-login .text-left {
    float: left;
}
.fontsize-15 {
    font-size: 15px;
}
.font-color {
    color: #7c7c7c;
}
.popup-login .margin-b15 {
    margin-bottom: 10px;
    display: flow-root;
}
.line-hr {
    display: table;
    white-space: nowrap;
    height: auto;
    line-height: 1;
    text-align: center;
    font-size: 1rem;
    margin: auto;
}
.line-hr:after, .line-hr:before {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
}
.line-hr:after, .line-hr:before {
    background-image: url('../../Assets/Img/line-hr.png');
}
.line-hr:before {
    background-position: right 1em top 50%;
}
.line-hr:after {
    background-position: left 1em top 50%;
}
h4.fontsize-20 {
    font-size: 19px;
    font-weight: bolder;
}
.popup-login h6 {
    text-align: center;
    color: #4c4c4c;
}
.sign-error {
    background-color: #fff6f6;
    color: #9f3a38;
    text-align: center;
    font-size: 14px;
    padding: 7px;
    margin-top: 7px;
    border: 1px solid #9f3a38;
    border-radius: 3px;
    display: block;
}
.success {
    background-color: #f6fff8;
    color: #389f41;
    text-align: center;
    font-size: 14px;
    padding: 7px;
    margin-top: 7px;
    border: 1px solid #389f41;
    border-radius: 4px;
    display: block;
}
.header-top-bar {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 0 15px 0px 6px;
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.header-mobileno {
    float: left;
    position: relative;
    list-style: none;
    margin: 0;
    font-size: 13px;
    padding: 10px 10px;
}
.phone-no {
    margin-right: 20px;
}
.phone-no a {
    color: #212121;
    cursor: pointer;
}
.phone-no a:focus, .phone-no a:active, .phone-no a:hover {
    text-decoration: none;
    color: #212121;
}
.header-top-bar-social {
    float: right;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}
.header-top-bar-social li {
    float: left;
    position: relative;
}
.header-top-bar-social li a {
    float: left;
    color: #565656;
    text-decoration: none;
    margin: 0;
    padding: 13px 0;
    width: 40px;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 1;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.header-top-bar-social li.navlast span {
    float: left;
    color: #565656;
    text-decoration: none;
    margin: 0;
    /* padding: 14px 0 13px 10px; */
    /* width: 40px; */
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 1;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-right: 1px solid rgba(0,0,0,.1);
    width: auto;
    font-weight: 400;
    padding: 13.5px 10px;
}
.header-top-bar-social li:last-child a {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    width: auto;
    font-weight: 400;
    padding: 13.5px 10px;
}
.header-top-bar-social li a:hover,
.header-top-bar-social li.navlast span:hover {
    background: #565656;
    color: #fff;
}
.header-top-bar-social li a span {
    font-weight: 700;
}
.menu-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    top: -5px;
    z-index: 100;
}
.menu-bar .navbar {  
    /* padding: .3rem 2.5rem; */
    padding: .4rem 16px;
    background-color: #ffffff!important;
}
.logo-100 {
    width: 186px;
}
.menu-bar .logo-lss {
    width: 100%;
}
.menu-bar .mr-auto {
    margin-left: auto;
    margin-right: 0px!important;
}
.menu-bar li.nav-item .nav-link {
    padding: 0px;
}
.menu-bar .navbar-nav li.nav-item:last-child{
    padding-right: 0px;
}
.menu-bar li.nav-item a,
.menu-bar li.nav-item.navhead-menu span {
    font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
    font-weight: 400;
    font-size: 14px;
    color: #000000de!important;
    cursor: pointer;
}
.menu-bar li.nav-item {
    padding-right: 2rem;
    color: #000000de!important;
}
.menu-bar li.nav-item a:hover,
.menu-bar li.nav-item.navhead-menu span:hover  {
    border-bottom: 2px solid #979797;
    padding-bottom: 6px;
}
/* .menu-bar li.nav-item a:focus, .menu-bar li.nav-item a:active {
    border-bottom: 2px solid #94c44b;
    padding-bottom: 6px;
} */
.menu-bar li.nav-item a:hover, .menu-bar li.nav-item a:focus, .menu-bar li.nav-item a:active{
    color: rgba(0,0,0,.87)!important;
    text-decoration: none;
}
/* .menu-bar li.nav-item a.nav-link:hover, .menu-bar li.nav-item a.nav-link:focus, .menu-bar li.nav-item a.nav-link:active {
    color: #99c753!important;
} */
.menu-bar li.nav-item a.remove-right-padding {
    padding-right: 0px;
}
.height-99 {
    height: 140px;
}
.custom-control-label::before {
    top: .10rem!important;
}
.custom-checkbox .custom-control-label::before {
    border-radius: .15rem!important;
}
.custom-control-label::after {
    top: .10rem!important;
}
.errorposition151 {
    position: absolute;
    top: 151px;
}
.errorposition205 {
    position: absolute;
    top: 205px;
}
.header-top-bar-social li a.padding-flag {
    padding: 9px 8px 7px!important;
}
@media only screen and (min-width: 280px) and (max-width: 479px) {
    .header-top-bar-social li a {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 32px;
        font-size: 10px;
    }
    .header-top-bar-social li a.padding-flag {
        padding: 6px 3px 6px!important
    }
    .header-top-bar {
        padding: 0px;
    }
    .menu-bar .navbar {
        padding: 10px;
        z-index: 1;
    border-bottom: 2px solid #ddd;
    }
    .header-mobileno {
        display: none;
    }
    .menu-bar li.nav-item:nth-child(1) {
        margin-top: 8px;
    }
    .menu-bar li.nav-item {
        padding-top: 10px;
        padding-bottom: 5px;
        padding-left: 15px;
    }
    .headeractive {
        border-bottom: 2px solid #fa7033;
        padding-bottom: 3px;
    }
    .menu-bar .mr-auto {
        margin-top: 3px!important;
    }
    .menu-bar li.nav-item {
        margin-bottom: 3px!important;
    }
}
@media only screen and (min-width: 480px) and (max-width: 767px){
    .header-mobileno {
        display: none;
    }
    .menu-bar .navbar {
        padding: 10px 20px;
    }
    .header-top-bar {
        padding: 0px 20px;
    }
    .menu-bar .mr-auto {
        margin-top: 10px!important;
    }
    .menu-bar li.nav-item {
        margin-bottom: 10px!important;
    }
 }
 .margin-17t {
    margin-top: -17px;
 }
 @media only screen and (min-width: 768px) and (max-width: 1150px){
    .desktop-insight-details .button-with-icon{
        margin-top: 10px;
    }
    .property-address-right table{
        margin-bottom: 15px;
    }
    .property-address-right table tr td:first-child,
    .property-address-right table tr td:nth-child(2){
        padding-bottom: 5px;
    }
    .property-address-right table td{
        width: 50%;
        display: block;
        float: left;
    }
    body .property-address-right .updated-col{
        border-left: none;
        padding-left: 0px;
    }
    .property-table-layout .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
 }